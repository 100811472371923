import React from "react";
import classes from "./textarea.module.scss";

const Input = ({ placeholder, onChange, value, error }) => {
  return (
    <>
      <div
        style={{ border: error && "2px solid #f57355" }}
        className={classes.InputContainer}
      >
        <textarea
          onChange={onChange}
          className={classes.Input}
          placeholder={placeholder}
        />
        {value.length === 0 && (
          <span
            style={{ color: error && "#f57355" }}
            className={classes.Required}
          >
            *zorunlu
          </span>
        )}
      </div>
    </>
  );
};

export default Input;
