const paymentTypeList = [
  {
    id: 1,
    payment_type: "online",
    detail: "creditcard",
  },
  {
    id: 2,
    payment_type: "ondoor",
    detail: "cash",
  },
  {
    id: 3,
    payment_type: "ondoor",
    detail: "card",
  },
  {
    id: 4,
    payment_type: "ondoor",
    detail: "metropol",
  },
  {
    id: 5,
    payment_type: "ondoor",
    detail: "multinet",
  },
  {
    id: 6,
    payment_type: "ondoor",
    detail: "paye",
  },
  {
    id: 7,
    payment_type: "ondoor",
    detail: "setcard",
  },
  {
    id: 8,
    payment_type: "ondoor",
    detail: "sodexo",
  },
  {
    id: 9,
    payment_type: "ondoor",
    detail: "sodexoMobile",
  },
  {
    id: 10,
    payment_type: "ondoor",
    detail: "ticket",
  },
  {
    id: 11,
    payment_type: "ondoor",
    detail: "yemekmatik",
  },
];

export { paymentTypeList };
