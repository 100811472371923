import React from "react";
import classes from "./banner.module.scss";
import "./banner.css";
const BannerImage = process.env.PUBLIC_URL + "/images/6-layer.webp";

const Banner = () => {
  return (
    <>
      <div className={classes.Visual}>
        <div className={classes.VisualContainer}>
          <img className={classes.BannerImage} src={BannerImage}></img>
          <div className={classes.VisualContent}>
            <h3 className={classes.VisualTitle}>
              <b>
                <span style={{ color: "#f1b62a" }}>Go</span>fody'e
              </b>{" "}
              katıl, sende
              <br />
              siparişlerini arttır!
            </h3>
            <p className={classes.VisualText}>
              <span style={{ color: "#f1b62a" }}>Go</span>fody’deki seçili
              restoranlar arasında siz de yerinizi alın; işinizi daha az
              maliyetle büyütün, müşteri memnuniyetinizi artırın!
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
