import React, { useEffect, useState } from "react";
import CompleteForm from "../../containers/completeForm";
import Header from "../../containers/header";
import { Button, useMediaQuery } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useParams } from "react-router-dom";
import axios from "axios";

import { useHistory } from "react-router-dom";

const CompleteRegistration = () => {
  const { code } = useParams();
  const history = useHistory();
  const theme = createTheme();
  const matches = useMediaQuery(theme.breakpoints.up("sm"));
  const [registrationInfo, setRegistrationInfo] = useState(null);
  const [bannerInfo, setBannerInfo] = useState(null);

  useEffect(() => {
    if (code != null && code != undefined) {
      axios
        .get(
          `https://backend.gofody.com/api/v1/potentialcustomer/sale/restaurantsale/${code}/`
        )
        .then((response) => {
          setRegistrationInfo(response?.data?.response);
        });
    }
  }, [code]);

  useEffect(() => {
    axios
      .get(
        `https://backend.gofody.com/api/v1/potentialcustomer/sale/restaurantbanner/list`
      )
      .then((response) => {
        setBannerInfo(response?.data?.response);
      });
  }, []);

  return (
    <div>
      <Header type={"restaurant"} />
      <div
        style={{
          width: "100%",
          // height: "calc(100vh)",
          marginTop: matches ? 108 : 140,
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <img
          style={{
            width: "80%",
            height: matches ? 400 : "auto",
            borderRadius: 15,
          }}
          src={
            bannerInfo &&
            (matches ? bannerInfo[0]?.picture : bannerInfo[0]?.picture_mobil)
          }
          alt={"Announcement Carousel"}
        ></img>
        {/* <div
          style={{
            height: 400,
            //  marginTop: 50,
            width: matches ? "70%" : "90%",
            border: "groove",
            borderColor: "green",
            overflowWrap: "break-word",
          }}
        >
          <div style={{ margin: 10 }}>{code}</div>
        </div> */}
        <div style={{ marginTop: 20, width: "100%" }}>
          <CompleteForm
            registrationInfo={registrationInfo}
            code={code}
            history={history}
          />
        </div>
      </div>
    </div>
  );
};

export default CompleteRegistration;
