import React, { useEffect, useState } from "react";
import classes from "./banner.module.scss";
import { Formik, Form, Field } from "formik";
import Input from "../../components/input";
import axios from "axios";
import Select from "../../components/select";
import { ToastContainer, toast } from "react-toastify";
import ReactLoading from "react-loading";
import validationSchema from "./validationSchema";
import "react-toastify/dist/ReactToastify.css";
import RegistrationField from "../../components/registrationField";

const Banner = ({ id }) => {
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhonenumber] = useState("");
  const [townOptions, setTown] = useState({ value: "", label: "" });
  const [selectedTown, setSelectedTown] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [selectedPaket, setSelectedPaket] = useState("");
  const [cityOptions, setCity] = useState({});

  const [checked, setChecked] = useState(false);

  const [reason, setReason] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [formData, setFormData] = useState(null);

  useEffect(() => {
    axios
      .get("https://backend.gofody.com/api/v1/restaurant/city/list/")
      .then((response) => {
        const cityData = response.data.response.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
        setCity(cityData);
      });
  }, []);

  const paketOptions = [
    { value: "Paket Servisim Yok", label: "Paket Servisim Yok" },
    { value: "Var - Kendi Kuryem", label: "Var - Kendi Kuryem" },
    {
      value: "Var - Dışarıdan Hizmet Alıyorum",
      label: "Var - Dışarıdan Hizmet Alıyorum",
    },
  ];

  const initialValues = {
    name: name,
    surname: surname,
    email: email,
    phoneNumber: phoneNumber,
    reason: reason,
    city: selectedCity,
    town: selectedTown,
    packet: selectedPaket,
    message: message,
    checked: checked,
  };

  useEffect(() => {
    setFormData({
      name: "",
      surname: "",
      email: "",
      phoneNumber: "",
      reason: "",
      city: "",
      town: "",
      packet: "",
      message: "",
    });
  }, []);

  return (
    <div id={id} className={classes.Visual}>
      <div className={classes.VisualContainer}>
        <div className={classes.VisualContent}>
          <span className={classes.VisualTitle}>Restoran Ön Talep</span>
          <p className={classes.VisualText}>
            <span style={{ color: "#f1b62a" }}>Go</span>fody restoran iş
            ortaklarından biri olun, işinizi daha az maliyetle büyütüp müşteri
            memnuniyeti artırın
          </p>
          {/* <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <RegistrationField
              fieldHeader={"Restoran Adı"}
              fieldValue={formData?.name}
              fieldName={"name"}
              formData={formData}
              setFormData={setFormData}
            />
            <RegistrationField
              fieldHeader={"İsim soyisim"}
              fieldValue={formData?.surname}
              fieldName={"surname"}
              formData={formData}
              setFormData={setFormData}
            />
            <RegistrationField
              fieldHeader={"Email"}
              fieldValue={formData?.email}
              fieldName={"email"}
              formData={formData}
              setFormData={setFormData}
            />
            <RegistrationField
              fieldHeader={"Telefon Numarası"}
              fieldValue={formData?.phoneNumber}
              fieldName={"phoneNumber"}
              formData={formData}
              setFormData={setFormData}
            />
          </div> */}
          <Formik
            enableReinitialize={true}
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values) => {
              setLoading(true);
              axios
                .post(
                  "https://backend.gofody.com/api/v1/potentialcustomer/pre/register/",
                  {
                    restaurant_name: values.name,
                    full_name: values.surname,
                    city: values.city,
                    town: values.town,
                    phone: values.phoneNumber,
                    package: values.packet,
                    email: values.email,
                  }
                )
                .then((response) => {
                  setLoading(false);
                  setSuccess(true);
                  toast.success("Başvurunuz başarıyla gönderilmiştir.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                })
                .catch((error) => {
                  setLoading(false);
                  toast.error("Bir şeyler yolunda gitmedi. Tekrar deneyiniz.", {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
            }}
          >
            {({ values, onSubmit, errors }) => {
              return (
                <Form>
                  <div className={classes.Form}>
                    <Field
                      type={1}
                      error={errors.name}
                      name={"name"}
                      onChange={(e) => {
                        setName(e.target.value);
                      }}
                      value={values.name}
                      placeholder={"Restoran İsmi"}
                      component={Input}
                    />
                    <Field
                      error={errors.surname}
                      name={"surname"}
                      type={1}
                      style={{ marginLeft: "12px" }}
                      onChange={(e) => {
                        setSurname(e.target.value);
                      }}
                      value={values.surname}
                      placeholder={"İsim Soyisim"}
                      component={Input}
                    />
                    <Field
                      error={errors.email}
                      name={"email"}
                      type={1}
                      style={{ marginLeft: "12px" }}
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      value={values.email}
                      placeholder={"E-Posta"}
                      component={Input}
                    />
                    <div style={{ marginBottom: "20px" }} />
                    <Field
                      error={errors.phoneNumber}
                      name={"phoneNumber"}
                      type={1}
                      style={{ marginLeft: "12px" }}
                      onChange={(e) => {
                        setPhonenumber(e.target.value);
                      }}
                      value={values.phoneNumber}
                      placeholder={"Telefon No"}
                      component={Input}
                    />
                  </div>
                  <div className={classes.Form1}>
                    <Field
                      error={errors.city}
                      name={"city"}
                      type={1}
                      value={
                        selectedCity &&
                        cityOptions.filter(
                          (option) => option.value === selectedCity
                        )
                      }
                      onChange={(val) => {
                        setSelectedCity(val.value);
                        axios
                          .get(
                            `https://backend.gofody.com/api/v1/restaurant/town/list/?city=${val.value}`
                          )
                          .then((response) => {
                            const data = response.data.response.map((item) => {
                              return {
                                value: item.id,
                                label: item.name,
                              };
                            });
                            setTown(data);
                          });
                      }}
                      options={cityOptions}
                      placeholder={"İl Seçiniz"}
                      component={Select}
                    />
                    <Field
                      error={errors.town}
                      name={"town"}
                      type={1}
                      marginLeft={window.innerWidth < 1024 ? "0px" : "12px"}
                      options={townOptions}
                      value={
                        selectedTown &&
                        townOptions.filter(
                          (option) => option.value === values.town
                        )
                      }
                      onChange={(val) => {
                        setSelectedTown(val.value);
                      }}
                      tabSelectsValue={selectedTown}
                      placeholder={"İlçe Seçiniz"}
                      component={Select}
                    />
                    <Field
                      error={errors.packet}
                      name={"packet"}
                      type={1}
                      options={paketOptions}
                      marginLeft={window.innerWidth < 1024 ? "0px" : "12px"}
                      value={paketOptions.filter(
                        (option) => option.value === values.packet
                      )}
                      onChange={(val) => {
                        setSelectedPaket(val.value);
                      }}
                      tabSelectsValue={values.packet}
                      placeholder={"Paket Seçiniz"}
                      component={Select}
                    />
                  </div>

                  <span className={classes.textInfo}>
                    <span style={{ color: "#f1b62a" }}> Gofody</span> bu
                    bilgileri sadece başvurunuzu değerlendirmek için
                    kullanacaktır. Bilgileriniz başkalarıyla paylaşılmayacaktır.
                  </span>
                  <div className={classes.FormFooter}>
                    <div
                      onClick={onSubmit}
                      className={classes.checkboxContainer}
                    >
                      <Field
                        error={errors.check}
                        name={"checked"}
                        color={"#e5e5e5"}
                        value={values.checked}
                        onChange={() => {
                          setChecked(!checked);
                        }}
                        component={(props) => {
                          return (
                            <input
                              name={props.name}
                              onClick={props.onChange}
                              onChange={props.onChange}
                              value={props.value}
                              checked={props.value}
                              type={"checkbox"}
                              className={classes.checkbox}
                            />
                          );
                        }}
                      />
                      <p
                        className={classes.VisualText}
                        style={{
                          width: "85%",
                          fontWeight: 400,
                          marginLeft: "8px",
                        }}
                      >
                        <span style={{ color: "#f1b62a" }}>Go</span>fody ’e
                        sağladığım yukarıdaki bilgilerin doğruluğunu teyit
                        ederim. <span style={{ color: "#f1b62a" }}>Go</span>
                        fody’e sağladığım bu bilgiler üzerinden bana ulaşmasına
                        izin veriyorum.
                      </p>
                    </div>
                    <button
                      disabled={success}
                      className={classes.VisualButton}
                      type={"submit"}
                    >
                      {loading ? (
                        <ReactLoading
                          type={"spinningBubbles"}
                          color={"#000000"}
                          height={"40px"}
                          width={"40px"}
                        />
                      ) : (
                        <span>{success ? "Başarılı" : "Tamamla"}</span>
                      )}
                    </button>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div />
      <ToastContainer />
    </div>
  );
};

export default Banner;
