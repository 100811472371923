import React, { Children } from "react";

import CardHeader from "@mui/material/CardHeader";

export default function Default({ children, subheader = "", ...props }) {
  return (
    <div
      style={{
        textAlign: "left",
        padding: 16,
        backgroundColor: "#ffffff",
      }}
      {...props}
    >
      {children}
    </div>
  );
  return (
    <CardHeader
      // avatar={
      //   <Avatar aria-label="recipe" className={classes.avatar}>
      //     R
      //   </Avatar>
      // }
      // action={
      //   <IconButton aria-label="settings">
      //     <MoreVertIcon />
      //   </IconButton>
      // }
      title={children}
      subheader={subheader}
    />
  );
}

// CardHeader.propTypes = {
//   className: PropTypes.string,
//   color: PropTypes.oneOf([
//     "warning",
//     "success",
//     "danger",
//     "info",
//     "primary",
//     "rose",
//   ]),
//   plain: PropTypes.bool,
//   image: PropTypes.bool,
//   contact: PropTypes.bool,
//   signup: PropTypes.bool,
//   stats: PropTypes.bool,
//   icon: PropTypes.bool,
//   text: PropTypes.bool,
//   children: PropTypes.node,
// };
