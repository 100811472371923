import React, { useEffect } from "react";
import Header from "../../containers/header";
import classes from "./kvkk.module.scss";
const Kvkk = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header type="kvkk" />
      <div style={{ width: "90%", margin: "150px auto", maxWidth: "1000px" }}>
        <p>
          <h1>
            KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN MÜŞTERİ AYDINLATMA METNİ
          </h1>
          <br />
          <b>Veri Sorumlusu : Gofody Bilişim Hizmetleri A.Ş</b> (bundan sonra
          <b>“Gofody”</b> olarak anılacaktır.) (Burhaniye Mah Vasıf Bey Sok. No:
          2 Üsküdar /İstanbul adresinde mukim) olarak; kişisel verilerinizi 6698
          sayılı Kişisel Verilerin Korunması Kanunu <b>(“KVKK”)</b> ve ilgili
          mevzuat ve yasal düzenlemelerden kaynaklanan faaliyetleri çerçevesinde
          kişisel verilerinizin işlenmesi, saklanması ve aktarılması ile ilgili
          siz müşterilerimizi bilgilendirmek maksadıyla işbu aydınlatma metnini
          hazırladık.
          <br />
          <br />
          <b>
            Hangi Kişisel Verileriniz İşlenmekte ve Kişisel Verilerinizin
            İşlenme Amaçları Nelerdir?
          </b>
          <br />
          <br />
          Faaliyetlerimiz ve istihdam amaçlarımız kapsamında siz
          müşterilerimizin aşağıda yer alan verilerini toplamaktayız:
          <br />
          <br />
          <b style={{ textAlign: "center", margin: "0 auto" }}>
            İşlenen Kişisel Verileriniz
          </b>
          <br />
          <br />
          <div className={classes.RowColored}>
            <div>Kimlik ve İletişim Bilgileri</div>
            <div> Finans Bilgileri </div> <div> İşlem Güvenliği Bilgileri</div>
            <div> Müşteri İşlem Bilgileri</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Ad,Soyad</li> <li>Cinsiyet</li> <li>Yaş</li>
                <li> Cep Telefonu no,</li> <li>E-posta</li> <li>Adres,</li>
                <li>Konum Bilgileri</li>
              </ul>
            </div>
            <div>
              <ul>
                <li> Ödeme yöntemleri ve ödemelere ilişkin bilgiler</li>
                <li> Kredi Kartı Bilgileri</li>
              </ul>
            </div>
            <div>
              <ul>
                <li> IP Bilgisi </li> <li> Browser Bilgisi </li>
                <li> Aplikasyon kullanım bilgileri</li>
                <li> IP, kullanıcı işlem kayıtları </li>
                <li> Cihaz türü, cihaz işletim sistemi,donanım modeli,</li>
              </ul>
            </div>
            <div>
              <ul>
                <li> Sipariş Bilgisi</li> <li> Talep Bilgisi</li>
                <li> Fatura Bilgisi</li> <li> Müşteri Numarası </li>
                <li> Tercih, beğeni, sipariş analizleri </li>
                <li> Alışveriş ve sipariş geçmişi,</li>
              </ul>
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, Gofody tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’inci maddesinde
              belirtilen hukuki sebepler ve ;
              <ul>
                <li>
                  Gofody ile aranızda bir sözleşmenin kurulması veya ifası için
                  gerekli olması,
                </li>
                <li> Bir hakkın tesisi, kullanılması ve korunması için,</li>
                <li>Hukuki yükümlülüklerimizi yerine getirebilmek için,</li>
              </ul>
              Temel hak ve özgürlüklerinize zarar vermemek kaydıyla, meşru
              menfaatlerimiz için veri işlenmesinin zorunlu olması hukuki
              sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizi Toplama Yöntemleri </div>
            <div>
              Yukarıda belirtilen kişisel verileriniz Gofody mobil aplikasyonu
              ve web sayfası vasıtasıyla otomatik veya yarı otomatik yöntemler
              ile toplanmaktadır.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizi İşleme Amaçları</div>
            <div>
              <ul>
                <li> Mobil aplikasyon üyelik kaydını gerçekleştirmek,</li>
                <li>
                  Web sitesi/mobil uygulamalar üzerinden alışveriş
                  yapanın/yaptıranın kimlik bilgilerini teyit etmek,
                </li>
                <li>
                  Sipariş, ödeme, teslimat ve iletişim süreçlerini yönetmek için
                  adres ve diğer gerekli bilgileri kaydetmek,
                </li>
                <li>
                  elektronik,internet,mobil vs. ortamında işleme dayanak olacak
                  tüm kayıt ve bilgileri düzenlemek,analizler yapmak,
                </li>
                <li>
                  Kuryeler, restoranlar ve müşteriler arasındaki iletişimi
                  yürütmek,
                </li>
                <li>
                  Mesafeli satış sözleşmesi ve Tüketicinin Korunması Hakkında
                  Kanun'un ilgili maddeleri ve akdettiğimiz sözleşmeler uyarınca
                  üstlenilen yükümlülükleri ifa etmek,
                </li>
                <li></li> Müşteri memnuniyetli aktiviteleri yapmak,
                <li>
                  kamu güvenliğine ilişkin hususlarda talep halinde ve mevzuat
                  gereği kamu görevlilerine bilgi verebilmek,
                </li>
                <li>
                  müşterilerimize daha iyi alışveriş deneyimi sağlamak,
                  müşterilerimizin ilgi alanlarını dikkate alarak
                  müşterilerimize bilgi vermek, kampanyaları aktarmak,
                </li>
                <li>
                  müşteri memnuniyetini artırmak, web sitesi ve/veya mobil
                  uygulamalardan alışveriş yapan müşterilerimizi tanıyabilmek ve
                  müşteri çevresi analizi yapmak, pazarlama ve reklam
                  faaliyetleri geliştirebilmek ve bu kapsamda anlaşmalı
                  kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki
                  ortamda anketler düzenlemek,
                </li>
                <li>
                  anlaşmalı kurumlarımız ve çözüm ortaklarımız tarafından
                  müşterilerimize öneri sunabilmek, hizmetlerimizle ilgili
                  müşterilerimizi bilgilendirmek,
                </li>
                <li>
                  hizmetlerimiz ile ilgili şikayet ve talepleri değerlendirmek,
                </li>
                <li> Ödemeleri, finans ve muhasebe işlemlerini yürütmek</li>
                <li> Faaliyetlerimizi mevzuata uygun yerine getirmek</li>
              </ul>
            </div>
          </div>
          <br />
          <br />
          <b>
            Kişisel Verileriniz Kimlere ve Hangi Amaçlarla Aktarılabilecektir?
          </b>
          <br />
          Kişisel verileriniz aşağıda belirtilen ilgili taraflara yine aşağıdaki
          amaçlarla aktarılabilir: <br />
          <br />
          <div className={classes.RowColored}>
            <div>Restoranlar ve Kuryeler</div>
            <div>Tedarikçiler,İş Ortakları,Taşeronlar</div>
            <div> Yetkili Kurum yada Kuruluşlar</div>
          </div>
          <div className={classes.Row}>
            <div>
              Kuryeler, restoranlar ve müşteriler arasındaki iletişimi sağlamak,
              Müşteri sipariş işlemlerini yürütmek, Kuryeler vasıtasıyla
              siparişin teslimini sağlamak.
            </div>
            <div>
              Ödemeleri, finans ve muhasebe işlemlerini yürütmek, web sitesi
              ve/veya mobil uygulamalardan alışveriş yapan müşterilerimizi
              tanıyabilmek ve müşteri çevresi analizi yapmak, Pazarlama ve
              reklam faaliyetleri geliştirebilmek ve bu kapsamda anlaşmalı
              kuruluşlar aracılığıyla elektronik ortamda ve/veya fiziki ortamda
              anketler düzenlemek.
            </div>
            <div>
              Hukuki süreçleri yürütmek,
              <br /> Faaliyetlerimizi mevzuata uygun yerine getirmek.
            </div>
          </div>
          <br />
          <br />
          Gofody olarak, faaliyetimizin yurt dışını kapsaması halinde verinizin
          işlendiği süreç yurt dışı bağlantılı ise iş faaliyetlerinin
          yürütülmesi amacı ile gerekli teknik ve idari tedbirleri alarak, bu
          kişilerle ve süreçlerle sınırlı veriler Kanun’daki kurallara uygun
          olarak yurt dışındaki iştirakler, bağlı ortaklıklar, tedarikçiler ile
          ilgili ülkedeki mevzuat zorunlulukları durumunda yabancı kamu kurum ve
          kuruluşları ile paylaşılabilecektir. <br /> <br />
          <b> Kişisel Verileriniz Ne Kadar Süre ile Saklanır? </b>
          <br /> <br />
          Kişisel verileriniz, kanunda veya ilgili mevzuatta verinin saklanması
          için bir süre belirlenmişse, söz konusu veri en az bu süre kadar
          saklanmak zorundadır. Kanunda veya ilgili mevzuatta bir süre
          öngörülmemişse; işlenme amaçlarıyla bağlantılı, sınırlı ve ölçülü
          olmak kaydıyla belirlenen makul sürelerce saklanmaktadır. <br />
          <br />
          <b>Kişisel Verileriniz ile İlgili Haklarınız Nelerdir?</b> <br />
          <br />
          KVKK ve ilgili mevzuat kapsamında kişisel verilerinize ilişkin olarak:
          <br />
          <ul>
            <li> Kişisel verilerinizin işlenip işlenmediğini öğrenme, </li>
            <li>
              Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
            </li>
            <li>
              Kişisel verilerin işlenme amacını ve bunların amacına uygun
              kullanılıp kullanılmadığını öğrenme,
            </li>
            <li>
              Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
              üçüncü kişileri bilme,
            </li>
            <li>
              Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
              bunların düzeltilmesini isteme,
            </li>
            <li>
              KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel
              verilerinizin silinmesini veya yok edilmesini isteme,
            </li>
            <li>
              Eksik veya yanlış verilerin düzeltilmesi ile kişisel verilerinizin
              silinmesi veya yok edilmesini talep ettiğinizde, bu durumun
              kişisel verilerinizi aktardığımız üçüncü kişilere bildirilmesini
              isteme,
            </li>
            <li>
              Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara
              uğramanız halinde bu zararın giderilmesini talep etme haklarına
              sahipsiniz.
            </li>
          </ul>
          <br />
          <b> Haklarınızı Nasıl Kullanabilirsiniz?</b>
          <br /> <br /> Kişisel verileriniz ile ilgili başvuru ve taleplerinizi
          Gofody’e bildirmek için aşağıdaki yöntemlere başvurabilirsiniz: <br />
          <br />
          <ul>
            <li>
              Islak imzalı ve kimlik fotokopisi ile Burhaniye Mah. Vasıf Bey
              Sok. No:2 Üsküdar/İstanbul adresine göndererek,
            </li>
            <li>
              Geçerli bir kimlik belgesi ile birlikte Gofody Bilişim Hizmetleri
              A.Ş’ye bizzat başvurarak,
            </li>
            <li>
              Mobil imza veya güvenli elektronik imza ile imzalayıp
              kvk@gofody.com adresine e-posta göndererek.
            </li>
          </ul>
          <br />
          <br />
          Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca,
          başvurunuzda isim, soyisim, başvuru yazılı ise imza, T.C. kimlik
          numarası (başvuruda bulunan kişinin yabancı olması halinde uyruğu,
          pasaport numarası veya varsa kimlik numarası),tebligata esas yerleşim
          yeri veya iş yeri adresi, varsa bildirime esas elektronik posta
          adresi, telefon numarası ve faks numarası ile talep konusuna dair
          bilgilerinizin bulunması zorunludur. <br />
          <br /> Yukarıda belirtilen hakları kullanmak için yapacağınız ve
          kullanmayı talep ettiğiniz hakka ilişkin açıklamaları içeren
          başvurunuzda talep edilen husus açık ve anlaşılır şekilde
          belirtmelidir. Başvurunuza ilişkin bilgi ve belgelerin başvuruya
          eklenmesi gerekmektedir. <br />
          <br /> Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle
          birlikte, başkası adına hareket ediliyor ise başvuruyu yapanın bu
          konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi
          (vekâletname) gerekmektedir. Ayrıca başvurunun kimlik ve adres
          bilgilerini içermesi ve başvuruya kimliği doğrulayıcı belgelerin
          eklenmesi gerekmektedir. <br />
          <br /> Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler
          değerlendirmeye alınmayacaktır. <br />
          <br />
          <b>
            Kişisel Verilerinizin İşlenmesine İlişkin Talepleriniz Ne Kadar
            Sürede Cevaplanır?
          </b>
          <br />
          <br />
          Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek, bize
          ulaştığı tarihten itibaren en geç 30 gün içerisinde cevaplanır.
          Başvurunuzun olumsuz değerlendirilmesi halinde gerekçeli ret sebepleri
          başvuruda belirttiğiniz adrese elektronik posta veya posta yolu başta
          olmak üzere seçilen usullerden biri ile gönderilir.
        </p>
      </div>
    </>
  );
};

export default Kvkk;
