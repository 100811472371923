import React from "react";
import classes from "./whatIs.module.scss";

const icon1 = process.env.PUBLIC_URL + "/icons/9-layer.webp";
const icon2 = process.env.PUBLIC_URL + "/icons/19-layer.webp";
const icon3 = process.env.PUBLIC_URL + "/icons/13-layer.webp";
const PackageImage = process.env.PUBLIC_URL + "/images/gelal.svg";
const HandImage =
  process.env.PUBLIC_URL + "/images/close-up-hand-holding-tasty-burger.svg";

const title1 = "İşte geldik, kapıyı aç!";
const desc1 =
  "Sana en yakın mesafeye göre sıralanmış restoranlardan dilediğin yemeği bi’ tıkla seç, siparişin 20-30 dakika içinde kapında olsun. Sen sadece kapıyı aç. Üstelik siparişini, restorandan çıkıp sana ulaşana kadar takip edebilirsin.";
const title2 = "Sen yemeğini değil, yemeğin seni beklesin.";
const desc2 =
  "Restorana gitmeden önce dilediğin yemeği seç, siparişini ver, istediğin saatte hazır olsun. Hiç sıra beklemeden paketini Gel Al.";
const title3 = "Yemeğin, seninle aynı anda masaya gelsin!";
const desc3 =
  "Dilediğin restorandan, dilediğin saatte seçtiğin yemeği online olarak sipariş ver, masanı ayır, restorana geldiğinde yemeğin de masana hazır gelsin. ";

const WhatIsArea = ({ id }) => {
  const infoCardRender = (title, desc, icon, icon2x, icon3x) => {
    return (
      <div className={classes.infoContainer}>
        <img className={classes.infoIcon} src={icon}></img>
        <div className={classes.textContainer}>
          <span className={classes.infoTitle}>{title}</span>
          <span className={classes.infoDesc}>{desc}</span>
        </div>
      </div>
    );
  };

  return (
    <div className={classes.WhatIsContainer} id={id}>
      <div className={classes.CountDownWrapper}>
        <div className={classes.WhatIsTitleWrapper}>
          <span className={classes.WhatIsTitleWhite}>Go</span>
          <span className={classes.WhatIsTitle}>
            fody <br />
            Nedir?{" "}
          </span>
        </div>
        <img className={classes.packageImage} src={PackageImage}></img>
      </div>
      <div className={classes.CountDownWrapper}>
        <div className={classes.rowContainer}>
          {infoCardRender(title1, desc1, icon1)}
          {infoCardRender(title3, desc3, icon3)}
        </div>
        <div
          className={classes.rowContainer}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {infoCardRender(title2, desc2, icon2)}
          <img className={classes.packageImage} src={HandImage}></img>
        </div>
      </div>
    </div>
  );
};

export default WhatIsArea;
