import React, { useContext, useState } from "react";
import Modal from "@mui/material/Modal";
import Card from "../card/Card";
import CardHeader from "../card/CardHeader";
import { Button } from "@mui/material";
import { Close } from "@mui/icons-material";

const MemberContract = ({
  modalHeader,
  memberContractModal,
  setMemberContractModal,
  contractPdf,
}) => {
  return (
    <Modal
      onClose={() => {
        setMemberContractModal(false);
      }}
      open={memberContractModal}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Card
        style={{
          boxShadow: "none",
          width: "100%",
          height: "100%",
        }}
      >
        <CardHeader
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            height: 60,
            borderRadius: 15,
            justifyContent: "space-between",
            // alignItems: "space-between",
            alignItems: "center",
            backgroundColor: "aliceblue",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 30,
            }}
          >
            {/* <img src={registration_person} width={30} height={30} /> */}
            <span
              style={{
                marginLeft: 5,
                fontFamily: "CoRg",
                fontWeight: 400,
                color: "#f1b62a",
                fontSize: 20,
              }}
            >
              {modalHeader}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginRight: 30,
            }}
          >
            {/* <img src={registration_person} width={30} height={30} /> */}
            <Button
              style={{
                backgroundColor: "#f1b62a",
                borderRadius: 12,
                width: "20%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              variant="contained"
              onClick={() => {
                setMemberContractModal(false);
              }}
            >
              <Close
                style={{
                  color: "#1d1d1d",
                  cursor: "pointer",
                }}
              />
            </Button>
          </div>
        </CardHeader>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            height: "95%",
          }}
        >
          <iframe src={contractPdf} title={"q"} width="98%" height={"100%"} />
        </div>
      </Card>
    </Modal>
  );
};

export default MemberContract;
