import React, { useState, useEffect, useRef } from "react";
import classes from "./header.module.scss";
import { Link } from "react-router-dom";
import icon1 from "../../assets/headerIcon.svg";
import icon2 from "../../assets/headerIcon2.svg";
import gofodylogo from "../../assets/gofodylogo.png";
import { Link as Linke } from "react-scroll";
const Logo = process.env.PUBLIC_URL + "/logo/logo.svg";
const LogoMobile = process.env.PUBLIC_URL + "/logo/logomobil.svg";

const Header = ({ type }) => {
  const [activeLink, setactiveLink] = useState(1);
  const [click, setclick] = useState(false);
  const bottomEndRef = useRef(null);
  useEffect(() => {
    window.onscroll = () => {
      if (window.pageYOffset < 100) setactiveLink(1);
      if (
        window.innerHeight + window.scrollY >=
        document.body.offsetHeight - 150
      )
        setactiveLink(3);
    };
  }, []);

  return (
    <>
      <div className={classes.Header}>
        <div className={classes.HeaderContent}>
          <div className={classes.LogoWrapper}>
            <Link className={classes.Link} to={"/"}>
              <img src={gofodylogo} className={classes.Logo} />
            </Link>
          </div>
          <div className={classes.LinkWrapper}>
            <Link
              duration={150}
              offset={0}
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
                setclick(true);
                setactiveLink(1);
                setTimeout(() => {
                  setclick(false);
                }, 500);
              }}
              to={"/"}
            >
              <div
                className={
                  activeLink === 1
                    ? classes.LinkContainerActive
                    : classes.LinkContainer
                }
              >
                <span
                  className={
                    activeLink === 1 ? classes.LinkActive : classes.Link
                  }
                >
                  Anasayfa
                </span>
              </div>
            </Link>
            {type != "restaurant" && type != "kvkk" && (
              <div
                className={
                  activeLink === 2
                    ? classes.LinkContainerActive
                    : classes.LinkContainer
                }
              >
                <Linke
                  offset={-120}
                  to="WhatIsArea"
                  onSetActive={() => {
                    !click && setactiveLink(2);
                  }}
                  duration={150}
                  spy={true}
                  smooth={true}
                >
                  <span
                    className={
                      activeLink === 2 ? classes.LinkActive : classes.Link
                    }
                  >
                    Gofody Nedir?
                  </span>
                </Linke>
              </div>
            )}
            {type != "restaurant" && type != "kvkk" && (
              <div
                className={
                  activeLink === 3
                    ? classes.LinkContainerActive
                    : classes.LinkContainer
                }
                style={{ marginRight: "60px" }}
                ref={bottomEndRef}
              >
                <Link
                  duration={150}
                  offset={-140}
                  onClick={() => {
                    window.scrollTo({
                      top: 3 * window.innerHeight - 300,
                      behavior: "smooth",
                    });
                    setclick(true);
                    setactiveLink(3);
                    setTimeout(() => {
                      setclick(false);
                    }, 500);
                  }}
                  to={"Footer"}
                >
                  <span
                    className={
                      activeLink === 3 ? classes.LinkActive : classes.Link
                    }
                  >
                    Bize Ulaş
                  </span>
                </Link>
              </div>
            )}
            {/* {type != "restaurant" && type != "kvkk" && (
              <div className={classes.LinkContainer}>
                <Linke
                  offset={-150}
                  to="Footer"
                  spy={true}
                  smooth={true}
                  style={{ marginRight: "60px" }}
                >
                  +Blog
                </Linke>
              </div>
            )} */}
            {type != "restaurant" && (
              <Link to={"/restaurant"}>
                <div className={classes.Button} style={{ minWidth: 180 }}>
                  <img src={icon2} className={classes.ButtonIcon} />
                  <span className={classes.ButtonText}>Restoran Başvurusu</span>
                </div>
              </Link>
            )}
            {type != "restaurant" && (
              <div
                onClick={() =>
                  window.open("https://restoran.gofody.com/login", "_blank")
                }
              >
                <div className={classes.Button}>
                  <img src={icon1} className={classes.ButtonIcon} />
                  <span className={classes.ButtonText}>Restoran Girişi</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={classes.HeaderMobile}>
        <div className={classes.HeaderContent}>
          <div className={classes.LogoWrapper}>
            <Link className={classes.Link} to={"/"}>
              <img src={gofodylogo} className={classes.LogoMobile} />
            </Link>
          </div>

          <div
            className={classes.LinkWrapper}
            style={{ flexDirection: "column" }}
          >
            {type === "restaurant" && (
              <Link
                offset={0}
                onClick={() => {
                  window.scrollTo({
                    top: 0,
                    behavior: "smooth",
                  });
                  setactiveLink(1);
                }}
                to={"/"}
              >
                <div
                  className={
                    activeLink === 1
                      ? classes.LinkContainerActive
                      : classes.LinkContainer
                  }
                >
                  <span
                    className={
                      activeLink === 1 ? classes.LinkActive : classes.Link
                    }
                  >
                    Anasayfa
                  </span>
                </div>
              </Link>
            )}

            {type != "restaurant" && (
              <Link className={classes.Link} to={"/restaurant"}>
                <div className={classes.Button}>
                  <img src={icon2} className={classes.ButtonIcon} />
                  <span className={classes.ButtonText}>Restoran Başvurusu</span>
                </div>
              </Link>
            )}
            {type != "restaurant" && (
              <div
                className={classes.Link}
                style={{ marginTop: 8 }}
                onClick={() =>
                  window.open("https://restoran.gofody.com/login", "_blank")
                }
              >
                <div className={classes.Button}>
                  <img src={icon1} className={classes.ButtonIcon} />
                  <span className={classes.ButtonText}>Restoran Girişi</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
export default Header;
