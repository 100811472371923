import React, { useEffect } from "react";
import Header from "../../containers/header";
import Banner from "../../containers/restaurantBanner";
import Form from "../../containers/restaurantForm";
import Footer from "../../containers/footer";
import WhatIsArea from "../home/whatIsArea";

const Restaurant = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header type={"restaurant"} />
      <Banner />
      <Form id={"restForm"} />
      <WhatIsArea id={"WhatIsArea"} />
      <div style={{ marginBottom: 100 }} />
      <Footer type={2} id={"Footer"} />
    </>
  );
};

export default Restaurant;
