import React from "react";
import { FormHelperText, InputBase } from "@mui/material";

const InputField = ({
  fieldValue,
  fieldHeader,
  type,
  action,
  required,
  fieldName,
  formData,
  setFormData,
  errors,
}) => {
  const handleChange = (name) => (event) => {
    let valueData = event.target ? event.target.value : event;
    let newDataRest = { ...formData };
    if (name == "contact_user.name") {
      newDataRest.contact_user.users[0] = {
        ...newDataRest.contact_user.users[0],
        ["firstname"]: valueData,
      };
    } else if (name == "contact_user.family_name") {
      newDataRest.contact_user.users[0] = {
        ...newDataRest.contact_user.users[0],
        ["lastname"]: valueData,
      };
    } else if (name == "contact_user.phone") {
      newDataRest.contact_user.users[0] = {
        ...newDataRest.contact_user.users[0],
        ["phone"]: valueData,
      };
    } else if (name == "contact_user.email") {
      newDataRest.contact_user.users[0] = {
        ...newDataRest.contact_user.users[0],
        ["email"]: valueData,
      };
    } else {
      newDataRest = { ...formData, [name]: valueData };
    }

    setFormData(newDataRest);
  };
  return (
    <div
      style={{
        flex: 1,
        backgroundColor: "#f4f4f4",
        // height: 62,
        borderRadius: 6,
        marginTop: 14,
        //   width: 120,
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            marginLeft: 10,
            fontSize: 12,
            fontWeight: "400",
            color: "#585858",
          }}
        >
          {fieldHeader}
          <span
            style={{
              marginLeft: 2,
              fontSize: 12,
              fontWeight: "400",
              color: "red",
            }}
          >
            *
          </span>
        </span>
        {/* <span
          style={{
            marginLeft: 10,
            fontSize: 12,
            fontWeight: "400",
            color: "red",
          }}
        >
          * zorunlu
        </span> */}
      </div>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <InputBase
          error={!!errors}
          size="small"
          required={required}
          spellCheck="false"
          variant="filled"
          style={{
            width: "100%",
            color: "black",
            marginLeft: 15,
            fontSize: 16,
            fontWeight: "400",
          }}
          value={fieldValue}
          onChange={handleChange(fieldName)}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </div>
    </div>
  );
};

export default InputField;
