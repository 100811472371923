import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  phoneNumber: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  town: Yup.string().required("Required"),
  packet: Yup.string().required("Required"),
  checked: Yup.boolean().oneOf([true], "Message"),
});

export default ValidationSchema;
