import React, { useEffect } from "react";

const Download = () => {
  useEffect(() => {
    const link = document.createElement("a");
    link.href =
      "https://gofody-music.eu-central-1.linodeobjects.com/Gofody%20Restoran%20Setup.exe";
    link.setAttribute("download", `gofody.exe`);
    // Append to html link element page
    document.body.appendChild(link);
    // Start download
    link.click();
    // Clean up and remove the link
    link.remove();
  }, []);
  return <div></div>;
};
export default Download;
