import React, { useEffect, useState } from "react";
import Header from "../../containers/header";
import Banner from "../../containers/banner";
import Visuals from "../../containers/visuals";
import Footer from "../../containers/footer";
import WhatIsArea from "./whatIsArea";
import { useHistory } from "react-router-dom";

const Home = () => {
  const history = useHistory();
  return (
    <div style={{ overflow: "hidden" }}>
      <Header />
      <Banner history={history} />
      <WhatIsArea id={"WhatIsArea"} />
      <Visuals id={"Visuals"} />
      <Footer type={1} id={"Footer"} />
    </div>
  );
};

export default Home;
