import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import React from "react";
import googleMapStyles from "./map.js";
import GoogleMapReact from "google-map-react";

const Icon = process.env.PUBLIC_URL + "/icons/marker.svg";

export class MapContainer extends React.Component {
  static defaultProps = {
    center: {
      lat: 41.026804773096515,
      lng: 29.054377224411308,
    },
    zoom: 7,
  };
  createMapOptions() {
    return {
      panControl: false,
      mapTypeControl: false,
      scrollwheel: false,
      styles: [
        {
          stylers: [
            { saturation: -100 },
            { gamma: 0.8 },
            { lightness: 4 },
            { visibility: "on" },
          ],
        },
      ],
    };
  }
  render() {
    return (
      <div style={{ height: "350px", width: "95%" }}>
        <GoogleMapReact
          options={this.createMapOptions}
          bootstrapURLKeys={{ key: "AIzaSyBhjYplCHle9rh7CJtgPQiMuSrfIDjC2qc" }}
          defaultCenter={this.props.center}
          defaultZoom={this.props.zoom}
          zoom={14}
        >
          <img
            src={Icon}
            style={{ width: "64px" }}
            lat={41.026804773096515}
            lng={29.054377224411308}
          />
        </GoogleMapReact>
      </div>
    );
  }
}

export default MapContainer;
