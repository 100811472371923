import React, { useEffect, useState } from "react";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { makeStyles } from "@mui/styles";
import InputField from "../../components/inputField";
import axios from "axios";
import { AddBox, Info } from "@mui/icons-material";
import validator from "validator";
import {
  Button,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import MemberContract from "../../components/modals/memberContract";
import { useSnackbar } from "notistack";
import SecurityContract from "../../components/modals/securityContract";
import { paymentTypeList } from "../../components/converter/paymentTypeList";
import paymentTypeConverter from "../../components/converter/paymentType";
import { serviceTypeList } from "../../components/converter/serviceTypeList";
import serviceTypeConverter from "../../components/converter/serviceType";

const useStyles = makeStyles((theme) => ({
  visual: {
    width: "100%",
    marginBottom: 80,
  },
  visualContainer: {
    width: "100%",
    zIndex: 6,
    //marginLeft: "5%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  visualContent: {
    display: "flex",
    flexDirection: "column",
    flex: 2,
    width: "100%",
    alignItems: "center",
  },
  visualTitle: {
    textAlign: "start",
    color: "#000000",
    color: "#f1b62a",
    fontSize: 24,
    fontFamily: "Roboto Bold",
    alignSelf: "flex-start",
    marginLeft: "5%",
  },
  visualText: {
    textAlign: "start",
    color: "#313131",
    fontSize: 16,
    width: "90%",
    margin: "32px 0px",
    fontFamily: "Roboto Bold",
    fontStyle: "normal",
    letterSpacing: "normal",
    lineHeight: "normal",
    textAlign: "left",
  },
  root: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
    },
    "& .PrivateNotchedOutline-root-15.MuiOutlinedInput-notchedOutline": {
      borderColor: "white",
      borderWidth: 0,
    },
  },
}));

const CompleteForm = ({ registrationInfo, code, history }) => {
  const theme = createTheme();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [email, setEmail] = useState(null);
  //const [code, setCode] = useState(null);
  const [town, setTown] = useState(null);
  const [selectedTown, setSelectedTown] = useState("");
  const [selectedCity, setSelectedCity] = useState(null);
  const [foodCategory, setFoodCategory] = useState(null);
  const [city, setCity] = useState(null);
  const [neigh, setNeigh] = useState(null);
  const [formData, setFormData] = useState({});
  const [memberContractModal, setMemberContractModal] = useState(false);
  const [memberContract, setMemberContract] = useState(false);
  const [securityContract, setSecurityContract] = useState(false);
  const [securityContractModal, setSecurityContractModal] = useState(false);
  const [modalHeader, setModalHeader] = useState("");
  const [errors, setErrors] = useState({});
  const [hours, setHours] = useState({ start: "00:00", end: "23:59" });

  const matches = useMediaQuery(theme.breakpoints.up("sm"));

  const getCity = () => {
    axios
      .get("https://backend.gofody.com/api/v1/restaurant/city/list/")
      .then((response) => {
        setCity(response.data.response);
      });
  };

  const getTown = (city_id) => {
    setSelectedCity(city_id);
    axios
      .get(
        `https://backend.gofody.com/api/v1/restaurant/town/list/?city=${city_id}`
      )
      .then((response) => {
        setTown(response?.data?.response);
      });
  };

  const getNeigh = (town_id) => {
    axios
      .get(
        `https://backend.gofody.com/api/v1/restaurant/neighborhood/list/?city=${selectedCity}&town=${town_id}`
      )
      .then((response) => {
        setNeigh(response?.data?.response);
      });
  };

  const foodCategoryFunc = () => {
    axios
      .get(
        `https://backend.gofody.com/api/v1/officeuser/restaurant/foodcategory/list/`
      )
      .then((response) => {
        setFoodCategory(response?.data?.response);
      });
  };

  useEffect(() => {
    if (selectedCity != null) {
      getNeigh(registrationInfo?.town);
    }
  }, [selectedCity]);

  const handleChangeNeigh = (name) => (event) => {
    if (name == "city") {
      getTown(event.target.value);
    } else if (name == "town") {
      getNeigh(event.target.value);
    }
    let valueData = event.target ? event.target.value : event;
    let newData = { ...formData };
    newData = { ...formData, [name]: valueData };
    setFormData(newData);
  };
  const handleChangeFood = (name) => (event) => {
    let valueData = event.target ? event.target.value : event;
    let newData = { ...formData };
    newData.category[0] = valueData;
    setFormData(newData);
  };

  const handleChangeMaxDistance = (value) => {
    let newData = { ...formData };
    newData = { ...formData, ["max_distance"]: value };
    setFormData(newData);
  };

  const handlePaymentType = (item) => {
    let tempdata = { ...formData };
    let newList = [];

    if (
      tempdata.payment_type &&
      tempdata.payment_type[item.payment_type].find((el) => el == item.detail)
    ) {
      newList =
        tempdata.payment_type[item.payment_type]?.filter(
          (i) => i != item.detail
        ) || [];
    } else {
      newList = [...tempdata.payment_type[item.payment_type]];
      newList.push(item.detail);
    }
    newList = newList.sort();
    tempdata.payment_type[item.payment_type] = newList;
    setFormData(tempdata);
  };

  const handleServiceType = (item) => {
    let tempdata = { ...formData };
    let serviceIndex = tempdata?.service_type?.findIndex((el) => el == item);
    if (serviceIndex != -1) {
      tempdata?.service_type?.splice(serviceIndex, 1);
    } else {
      tempdata?.service_type?.push(item);
    }
    setFormData(tempdata);
  };

  const handleWorkingHoursDay = (item) => {
    let tempdata = { ...formData };

    let index = tempdata.working_hours.findIndex((el) => el.day == item.day);
    if (index != -1) {
      tempdata.working_hours[index].is_active = !item.is_active;
    }
    setFormData(tempdata);
  };

  const handleAllChange = (startEnd, e) => {
    let tempworkingHoursList = { ...formData };

    if (!e) return "";
    startEnd === "start"
      ? setHours({ ...hours, start: e.target.value })
      : setHours({ ...hours, end: e.target.value });

    tempworkingHoursList?.working_hours?.map((item, index, array) => {
      if (startEnd === "start") {
        item.hours.start = e.target.value;
      } else {
        item.hours.end = e.target.value;
      }
      return item;
    });

    setFormData(tempworkingHoursList);
  };

  useEffect(() => {
    if (registrationInfo != null) {
      setFormData({
        name: registrationInfo?.name,
        email: registrationInfo?.email,
        city: registrationInfo?.city,
        town: registrationInfo?.town,
        account_name: registrationInfo?.account_name,
        address: null,
        bank_name: registrationInfo?.bank_name,
        category: [],
        neighborhood: null,
        address: null,
        phone: registrationInfo?.phone,
        tax_no: registrationInfo?.tax_no,
        tax_authority: registrationInfo?.tax_authority,
        constant_phone: registrationInfo?.constant_phone,
        first_name: registrationInfo?.first_name,
        family_name: registrationInfo?.family_name,
        title: registrationInfo?.title,
        identity_number: registrationInfo?.identity_number,
        iban: registrationInfo?.iban,
        max_distance: registrationInfo?.max_distance,
        payment_frequency: "daily",
        payment_type: { ondoor: ["cash"], online: ["creditcard"] },
        service_type: ["order", "takeaway", "reserve"],
        working_hours: [
          {
            day: "Monday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
          {
            day: "Tuesday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
          {
            day: "Wednesday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
          {
            day: "Thursday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
          {
            day: "Friday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
          {
            day: "Saturday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
          {
            day: "Sunday",
            hours: {
              end: "23:59",
              start: "00:00",
            },
            is_active: true,
          },
        ],
        contact_user: {
          users: [
            {
              firstname: null,
              lastname: null,
              phone: null,
              email: null,
            },
          ],
        },
      });

      setSelectedCity(registrationInfo?.city);
      getCity();
      getTown(registrationInfo?.city);
      foodCategoryFunc();
    }
  }, [registrationInfo]);

  const completedRegistration = () => {
    setErrors({});
    if (securityContract == false || memberContract == false) {
      enqueueSnackbar(`Sözleşmeleri kabul ettiğinizden emin olunuz`, {
        variant: "error",
        preventDuplicate: true,
        autoHideDuration: 2000,
      });
    } else if (formData?.name == null || formData?.name == "") {
      setErrors({
        name: "Lütfen geçerli bir restoran adı giriniz.",
      });
    } else if (
      formData?.constant_phone == null ||
      formData?.constant_phone[0] != 0 ||
      formData?.constant_phone.match(/\d/g).length != 11
    ) {
      if (
        formData?.constant_phone != null &&
        formData?.constant_phone[0] != 0
      ) {
        setErrors({
          constant_phone: "Lütfen numaranızın 0 la başladığından emin olunuz.",
        });
      } else if (
        formData?.constant_phone != null &&
        formData?.constant_phone.match(/\d/g).length !== 11
      ) {
        setErrors({
          constant_phone: "Lütfen 11 haneli sabit hat numaranızı giriniz.",
        });
      } else {
        setErrors({
          constant_phone: "Lütfen restoran sabit telefonu giriniz.",
        });
      }
    } else if (
      formData?.email == "" ||
      formData?.email == null ||
      !validator.isEmail(formData?.email)
    ) {
      setErrors({
        email: "Lütfen geçerli bir mail adresi giriniz.",
      });
    } else if (formData?.city == null) {
      setErrors({
        city: "İl boş bırakılamaz.",
      });
    } else if (formData?.town == null) {
      setErrors({
        town: "İlçe boş bırakılamaz.",
      });
    } else if (formData?.max_distance == null) {
      setErrors({
        max_distance: "Lütfen km giriniz.",
      });
    } else if (formData?.neighborhood == null) {
      setErrors({
        neighborhood: "Mahalle boş bırakılamaz.",
      });
    } else if (formData?.address == null || formData?.address == "") {
      setErrors({
        address: "Adres boş bırakılamaz.",
      });
    } else if (formData?.category.length == 0) {
      setErrors({
        category: "Lütfen mutfak türü seçiniz.",
      });
    } else if (
      formData?.contact_user?.users[0]?.firstname == null ||
      formData?.contact_user?.users[0]?.firstname == ""
    ) {
      setErrors({
        c_first_name: "Yetkili ismi boş bırakılamaz.",
      });
    } else if (
      formData?.contact_user?.users[0]?.lastname == null ||
      formData?.contact_user?.users[0]?.lastname == ""
    ) {
      setErrors({
        c_family_name: "Yetkili soyisim boş bırakılamaz.",
      });
    } else if (
      formData?.contact_user?.users[0]?.phone == null ||
      formData?.contact_user?.users[0]?.phone == "" ||
      !validator.isMobilePhone(
        formData?.contact_user?.users[0]?.phone,
        "tr-TR"
      ) ||
      formData?.contact_user?.users[0]?.phone[0] != 0
    ) {
      if (
        formData?.contact_user?.users[0]?.phone != null &&
        formData?.contact_user?.users[0]?.phone[0] != 0
      ) {
        setErrors({
          c_phone: "Lütfen yetkili numaranızın 0 la başladığından emin olunuz.",
        });
      } else if (
        formData?.contact_user?.users[0]?.phone != null &&
        !validator.isMobilePhone(
          formData?.contact_user?.users[0]?.phone,
          "tr-TR"
        )
      ) {
        setErrors({
          c_phone: "Lütfen 11 haneli yetkili cep numaranızı giriniz.",
        });
      } else {
        setErrors({
          c_phone: "Lütfen yetkili telefonu giriniz.",
        });
      }
    } else if (
      formData?.contact_user?.users[0]?.email == "" ||
      formData?.contact_user?.users[0]?.email == null ||
      !validator.isEmail(formData?.contact_user?.users[0]?.email)
    ) {
      setErrors({
        c_email: "Lütfen geçerli bir email adresi giriniz.",
      });
    } else if (formData?.title == null || formData?.title == "") {
      setErrors({
        title: "Ünvan boş bırakılamaz.",
      });
    } else if (
      formData?.tax_no == null ||
      formData?.tax_no == "" ||
      formData?.tax_no.match(/\d/g).length != 10
    ) {
      if (
        !formData?.tax_no == "" &&
        formData?.tax_no != null &&
        formData?.tax_no.match(/\d/g).length !== 10
      ) {
        setErrors({
          tax_no: "Vergi numarasının 10 haneli olduğundan emin olunuz.",
        });
      } else {
        setErrors({
          tax_no: "Vergi numarası boş bırakılamaz.",
        });
      }
    } else if (
      formData?.tax_authority == null ||
      formData?.tax_authority == ""
    ) {
      setErrors({
        tax_authority: "Vergi dairesi boş bırakılamaz.",
      });
    } else if (formData?.first_name == null || formData?.first_name == "") {
      setErrors({
        first_name: "Yetkili adı boş bırakılamaz.",
      });
    } else if (formData?.family_name == null || formData?.family_name == "") {
      setErrors({
        family_name: "Yetkili soyadı boş bırakılamaz.",
      });
    } else if (
      formData?.identity_number == null ||
      formData?.identity_number == "" ||
      formData?.identity_number.match(/\d/g).length != 11
    ) {
      setErrors({
        identity_number:
          "Lütfen geçerli 11 haneli tc kimlik numaranızı giriniz.",
      });
    } else if (formData?.bank_name == null || formData?.bank_name == "") {
      setErrors({
        bank_name: "Banka ismi boş bırakılamaz.",
      });
    } else if (formData?.account_name == null || formData?.account_name == "") {
      setErrors({
        account_name: "Banka hesap adı boş bırakılamaz.",
      });
    } else if (
      formData?.iban == null ||
      formData?.iban == "" ||
      !validator.isIBAN(formData?.iban)
    ) {
      setErrors({
        iban: "İbanınızın TR ile başladığından, 26 haneli ve geçerli bir iban olduğundan emin olunuz.",
      });
    } else {
      setErrors({});
      axios
        .put(
          `https://backend.gofody.com/api/v1/potentialcustomer/sale/restaurantsale/${code}/`,
          formData
        )
        .then((response) => {
          if (response?.data?.success) {
            history.push("/");
            enqueueSnackbar(
              `Restoran ön talep kaydınızı tamamladınız.Kaydınızın devamı için en yakın zamanda iletişime geçilecek.`,
              {
                variant: "success",
                preventDuplicate: true,
                autoHideDuration: 2000,
              }
            );
          } else {
            enqueueSnackbar(
              `Restoran kaydı oluşturulurken bir hata oluştu.Lütfen bilgilerinizi kontrol ederek tekrar deneyiniz.`,
              {
                variant: "error",
                preventDuplicate: true,
                autoHideDuration: 2000,
              }
            );
          }
        });
    }
  };

  return (
    <div className={classes.visual}>
      <div className={classes.visualContainer}>
        <div className={classes.visualContent}>
          <span className={classes.visualTitle}>Şirket Bilgileri</span>
          <div style={{ width: matches ? "90%" : "90%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.title}
                  fieldName={"title"}
                  fieldHeader={"Unvan"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.title && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.title}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.tax_no}
                  fieldName={"tax_no"}
                  fieldHeader={"Vergi Numarası"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.tax_no && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.tax_no}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.tax_authority}
                  fieldName={"tax_authority"}
                  fieldHeader={"Vergi Dairesi"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.tax_authority && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.tax_authority}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.first_name}
                  fieldName={"first_name"}
                  fieldHeader={"Yetkili Adı"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.first_name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.first_name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.family_name}
                  fieldName={"family_name"}
                  fieldHeader={"Yetkili Soyadı"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.family_name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.family_name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.identity_number}
                  fieldName={"identity_number"}
                  fieldHeader={"TC-Kimlik No"}
                  type={"number"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.identity_number && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.identity_number}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Restoran Bilgileri</span>
          <div style={{ width: matches ? "90%" : "90%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  errors={errors}
                  required={true}
                  fieldValue={formData?.name}
                  fieldHeader={"Restoran Tabela İsmi"}
                  fieldName={"name"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  type="number"
                  errors={errors}
                  required={true}
                  fieldValue={formData?.constant_phone}
                  fieldName="constant_phone"
                  fieldHeader={"Restoran Sabit Telefon"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.constant_phone && errors.constant_phone != "" && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.constant_phone}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.email}
                  fieldHeader={"E-mail"}
                  fieldName="email"
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.email && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.email}
                  </FormHelperText>
                )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "#f4f4f4" }}
                >
                  <InputLabel
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#585858",
                    }}
                  >
                    İl Seçimi{" "}
                    <span style={{ color: "red", fontSize: 20 }}> *</span>
                  </InputLabel>
                  <Select
                    className={classes.root}
                    value={parseInt(formData?.city)}
                    onChange={handleChangeNeigh("city")}
                  >
                    {city?.map((cityy) => {
                      return (
                        <MenuItem value={parseInt(cityy?.id)} id={cityy?.id}>
                          {cityy?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {!!errors.city && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.city}
                  </FormHelperText>
                )}
              </div>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "#f4f4f4" }}
                >
                  {" "}
                  <InputLabel
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#585858",
                    }}
                  >
                    İlçe Seçimi{" "}
                    <span style={{ color: "red", fontSize: 20 }}> *</span>
                  </InputLabel>
                  <Select
                    className={classes.root}
                    value={parseInt(formData?.town)}
                    onChange={handleChangeNeigh("town")}
                  >
                    {town?.map((townn) => {
                      return (
                        <MenuItem value={parseInt(townn?.id)} id={townn?.id}>
                          {townn?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {!!errors.town && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.town}
                  </FormHelperText>
                )}
              </div>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "#f4f4f4" }}
                >
                  <InputLabel
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#585858",
                    }}
                  >
                    Mahalle Seçimi{" "}
                    <span style={{ color: "red", fontSize: 20 }}> *</span>
                  </InputLabel>
                  <Select
                    className={classes.root}
                    value={parseInt(formData?.neighborhood)}
                    onChange={handleChangeNeigh("neighborhood")}
                  >
                    {neigh?.map((neighh) => {
                      return (
                        <MenuItem value={neighh?.id} id={neighh?.id}>
                          {neighh?.name}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                {!!errors.neighborhood && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.neighborhood}
                  </FormHelperText>
                )}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.address}
                  fieldHeader={"Adres"}
                  fieldName={"address"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.address && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.address}
                  </FormHelperText>
                )}
              </div>
              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "#f4f4f4" }}
                  size="medium"
                >
                  <InputLabel
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#585858",
                    }}
                  >
                    Mutfak Tipi Seçiniz{" "}
                    <span style={{ color: "red", fontSize: 20 }}> *</span>
                  </InputLabel>

                  <Select
                    className={classes.root}
                    // value={
                    //   parseInt(formData?.category[0]) != undefined &&
                    //   parseInt(formData?.category[0])
                    // }
                    onChange={handleChangeFood("category")}
                  >
                    {foodCategory?.map((foods) => {
                      return (
                        <MenuItem value={foods.id} id={foods.id}>
                          <span style={{}}>{foods?.category}</span>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>

                {!!errors.category && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.category}
                  </FormHelperText>
                )}
              </div>

              <div
                style={{
                  marginTop: 20,
                  marginLeft: 10,
                  marginRight: 10,
                  marginBottom: 10,
                  width: "100%",
                }}
              >
                <FormControl
                  variant="outlined"
                  style={{ width: "100%", backgroundColor: "#f4f4f4" }}
                >
                  <InputLabel
                    style={{
                      marginTop: 10,
                      fontSize: 12,
                      fontWeight: "400",
                      color: "#585858",
                    }}
                  >
                    Teslimat yaptığınız maksimum km{" "}
                    <span style={{ color: "red", fontSize: 20 }}> *</span>
                  </InputLabel>
                  <Select
                    className={classes.root}
                    value={parseInt(formData?.max_distance)}
                    onChange={(e) => handleChangeMaxDistance(e.target.value)}
                  >
                    <MenuItem value={1000}>1 km</MenuItem>
                    <MenuItem value={2000}>2 km</MenuItem>
                    <MenuItem value={3000}>3 km</MenuItem>
                    <MenuItem value={4000}>4 km</MenuItem>
                    <MenuItem value={5000}>5 km</MenuItem>
                    <MenuItem value={6000}>6 km</MenuItem>
                    <MenuItem value={7000}>7 km</MenuItem>
                    <MenuItem value={8000}>8 km</MenuItem>
                    <MenuItem value={9000}>9 km</MenuItem>
                    <MenuItem value={10000}>10 km</MenuItem>
                    <MenuItem value={11000}>11 km</MenuItem>
                    <MenuItem value={12000}>12 km</MenuItem>
                    <MenuItem value={13000}>13 km</MenuItem>
                    <MenuItem value={14000}>14 km</MenuItem>
                    <MenuItem value={15000}>15 km</MenuItem>
                    <MenuItem value={16000}>16 km</MenuItem>
                    <MenuItem value={17000}>17 km</MenuItem>
                    <MenuItem value={18000}>18 km</MenuItem>
                    <MenuItem value={19000}>19 km</MenuItem>
                    <MenuItem value={20000}>20 km</MenuItem>
                  </Select>
                </FormControl>
                {!!errors.max_distance && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.max_distance}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Servis Tipleri</span>
          <div
            style={{
              width: matches ? "90%" : "90%",
            }}
          >
            {formData?.service_type && (
              <FormControl
                required
                // error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <FormLabel component="legend">
                  İstediğiniz servis tipini kapatabilirsiniz.
                </FormLabel>
                <FormGroup
                  style={{
                    width: "50%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                  row
                >
                  {serviceTypeList?.map((item, ind) => {
                    const cond = formData?.service_type?.findIndex(
                      (el) => el == item
                    );
                    return (
                      <FormControlLabel
                        style={{ width: matches ? "auto" : "100%" }}
                        control={
                          <Checkbox
                            // checked={false}
                            checked={cond != -1 ? true : false}
                            onChange={(e) => handleServiceType(item)}
                            name={item}
                          />
                        }
                        label={serviceTypeConverter(item)}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            )}
          </div>
        </div>

        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Çalıştığınız Günler</span>

          <div
            style={{
              width: matches ? "90%" : "90%",
            }}
          >
            {formData?.working_hours && (
              <FormControl
                required
                // error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <FormLabel component="legend">
                  Kapalı olduğunuz güne tıklayınız.
                </FormLabel>
                <FormGroup
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                  row
                >
                  {formData?.working_hours?.map((item) => {
                    return (
                      <FormControlLabel
                        style={{ width: matches ? "auto" : "100%" }}
                        control={
                          <Checkbox
                            // checked={false}
                            checked={item?.is_active}
                            onChange={(e) => handleWorkingHoursDay(item)}
                            name={item.day}
                          />
                        }
                        label={
                          item.day == "Monday"
                            ? "Pazartesi"
                            : item.day == "Tuesday"
                            ? "Salı"
                            : item.day == "Wednesday"
                            ? "Çarşamba"
                            : item.day == "Thursday"
                            ? "Perşembe"
                            : item.day == "Friday"
                            ? "Cuma"
                            : item.day == "Saturday"
                            ? "Cumartesi"
                            : "Pazar"
                        }
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            )}
          </div>
        </div>
        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Çalışma Saatleri</span>
          <div
            style={{
              width: matches ? "90%" : "90%",
              marginTop: 10,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Paper
                elevation={1}
                style={{
                  width: "auto",
                  display: "flex",
                  flexDirection: matches ? "row" : "column",
                  padding: 10,
                }}
              >
                <TextField
                  // className={classes.root}
                  variant="filled"
                  id="time"
                  label={<span style={{ fontSize: 15 }}>Açılış Saati</span>}
                  type="time"
                  value={hours.start}
                  onChange={(e) => {
                    handleAllChange("start", e);
                  }}
                  style={{
                    width: 260,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
                <TextField
                  // className={classes.root}
                  variant="filled"
                  id="time"
                  label={<span style={{ fontSize: 15 }}>Kapanış Saati</span>}
                  type="time"
                  value={hours.end}
                  onChange={(e) => {
                    handleAllChange("end", e);
                  }}
                  style={{
                    width: 260,
                    marginLeft: matches ? 20 : 0,
                    marginTop: matches ? 0 : 20,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    step: 300, // 5 min
                  }}
                />
              </Paper>
            </div>
          </div>
        </div>
        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Ödeme Yöntemleri</span>
          <div
            style={{
              width: matches ? "90%" : "90%",
            }}
          >
            {formData?.payment_type && (
              <FormControl
                required
                // error={error}
                component="fieldset"
                sx={{ m: 3 }}
                variant="standard"
                style={{
                  width: "100%",
                }}
              >
                <FormLabel component="legend">
                  Online Ödeme ve Nakit otomatik olarak seçilmiştir.
                </FormLabel>
                <FormGroup
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                  row
                >
                  {paymentTypeList?.map((item) => {
                    const cond = formData?.payment_type[
                      item.payment_type
                    ]?.findIndex((el) => el == item.detail);
                    return (
                      <FormControlLabel
                        style={{ width: matches ? "auto" : "100%" }}
                        control={
                          <Checkbox
                            // checked={false}
                            checked={cond != -1 ? true : false}
                            onChange={(e) => handlePaymentType(item)}
                            name={item.detail}
                            disabled={
                              (item.detail == "cash" ||
                                item.detail == "creditcard") &&
                              true
                            }
                          />
                        }
                        label={paymentTypeConverter(item.detail)}
                      />
                    );
                  })}
                </FormGroup>
              </FormControl>
            )}
          </div>
        </div>
        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Restoran Yetkili</span>
          <div style={{ width: matches ? "90%" : "90%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.contact_user?.users[0]?.firstname}
                  fieldName={"contact_user.name"}
                  fieldHeader={"Birinci Yetkili İsim"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.c_first_name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.c_first_name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.contact_user?.users[0]?.lastname}
                  fieldName={"contact_user.family_name"}
                  fieldHeader={"Birinci Yetkili Soyisim"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.c_family_name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.c_family_name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.contact_user?.users[0]?.phone}
                  fieldHeader={"Birinci Yetkili Telefon"}
                  fieldName={"contact_user.phone"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.c_phone && (
                  <FormHelperText
                    style={{ marginLeft: 30 }}
                    error
                    id="accountId-error"
                  >
                    {errors.c_phone}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.contact_user?.users[0]?.email}
                  fieldHeader={"Birinci Yetkili Email"}
                  fieldName={"contact_user.email"}
                  type={"email"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.c_email && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.c_email}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={classes.visualContent} style={{ marginTop: 20 }}>
          <span className={classes.visualTitle}>Banka Bilgileri</span>
          <div style={{ width: matches ? "90%" : "90%" }}>
            <div
              style={{
                display: "flex",
                flexDirection: matches ? "row" : "column",
              }}
            >
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.bank_name}
                  fieldName={"bank_name"}
                  fieldHeader={"Banka Adı"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.bank_name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.bank_name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.account_name}
                  fieldName={"account_name"}
                  fieldHeader={"Banka Hesap Adı"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.account_name && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.account_name}
                  </FormHelperText>
                )}
              </div>
              <div style={{ margin: 10, width: "100%" }}>
                <InputField
                  required={true}
                  fieldValue={formData?.iban}
                  fieldName={"iban"}
                  fieldHeader={"Iban No"}
                  type={"text"}
                  setFormData={setFormData}
                  formData={formData}
                />
                {!!errors.iban && (
                  <FormHelperText
                    style={{ marginLeft: 10 }}
                    error
                    id="accountId-error"
                  >
                    {errors.iban}
                  </FormHelperText>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            width: "50",
            marginTop: 40,
            display: "flex",
            alignItems: "center",
            //  justifyContent: "center",
          }}
        >
          <Checkbox
            color="secondary"
            checked={memberContract}
            onChange={() => {
              setMemberContract(!memberContract);
            }}
          />
          <span
            onClick={() => {
              setModalHeader("Üye İşyeri Sözleşmesi");
              setMemberContractModal(true);
            }}
            style={{
              textAlign: "start",
              fontSize: 16,
              fontFamily: "Roboto Bold",
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
          >
            Üye işyeri sözleşmesi
          </span>
          <Info
            onClick={() => {
              setModalHeader("Üye İşyeri Sözleşmesi");
              setMemberContractModal(true);
            }}
            style={{ width: 20, height: 20, marginLeft: 10, marginBottom: 5 }}
          />
        </div>
        <div
          style={{
            width: "50",
            marginTop: 10,
            display: "flex",
            alignItems: "center",
            //  justifyContent: "center",
          }}
        >
          <Checkbox
            color="secondary"
            checked={securityContract}
            onChange={() => {
              setSecurityContract(!securityContract);
            }}
          />
          <span
            onClick={() => {
              setModalHeader("Gizlilik Sözleşmesi");
              setSecurityContractModal(true);
            }}
            style={{
              textAlign: "start",
              fontSize: 16,
              fontFamily: "Roboto Bold",
              cursor: "pointer",
              textDecoration: "underline",
              color: "blue",
            }}
          >
            Gizlilik sözleşmesi
          </span>
          <Info
            onClick={() => {
              setModalHeader("Gizlilik Sözleşmesi");
              setSecurityContractModal(true);
            }}
            style={{ width: 20, height: 20, marginLeft: 10, marginBottom: 5 }}
          />
        </div>
      </div>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: 30,
        }}
      >
        <Button
          style={{
            backgroundColor:
              securityContract && memberContract ? "#f1b62a" : "#f4f4f4",
            borderRadius: 12,
            width: "60%",
            marginTop: 14,
            alignSelf: "flex-end",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          startIcon={<AddBox style={{ color: "#1d1d1d", cursor: "pointer" }} />}
          variant="contained"
          onClick={() => {
            setErrors({});
            completedRegistration();
          }}
          disabled={securityContract && memberContract ? false : true}
        >
          <span
            style={{
              color: "black",
              fontWeight: 700,
            }}
          >
            Başvuruyu Tamamla
          </span>
        </Button>
      </div>
      <ToastContainer />
      <MemberContract
        modalHeader={modalHeader}
        memberContractModal={memberContractModal}
        setMemberContractModal={setMemberContractModal}
        contractPdf={registrationInfo?.contract?.contract}
      />
      <SecurityContract
        modalHeader={modalHeader}
        securityContractModal={securityContractModal}
        setSecurityContractModal={setSecurityContractModal}
        contractPdf={registrationInfo?.contract?.additional_contract}
      />
    </div>
  );
};

export default CompleteForm;
