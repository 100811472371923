import React from "react";
import classes from "./banner.module.scss";
import "./banner.css";
import { Link } from "react-router-dom";
import appstore from "../../assets/appstore.svg";
import playstore from "../../assets/playstore.svg";
import A1 from "../animations/a1.svg";
import A2 from "../animations/a2.svg";
import A3 from "../animations/a3.svg";
import A4 from "../animations/a4.svg";
import A5 from "../animations/a5.svg";
import A6 from "../animations/a6.svg";
const BannerImage = process.env.PUBLIC_URL + "/banner/mainImage.webp";
const AddIcon = process.env.PUBLIC_URL + "/icons/plusWhite.svg";

const Banner = ({ history }) => {
  return (
    <div className={classes.BannerContainer}>
      <div className={classes.BannerContent}>
        <div className={classes.CountDownWrapper}>
          <div className={classes.BannerTitleWrapper}>
            <div className={classes.textWrapper}>
              <div style={{ marginRight: 10 }}>
                <span className={classes.BannerTitleWhite}>Go</span>
                <span className={classes.BannerTitle}>fody </span>
              </div>
              <div className={classes.BannerTitleRegular}>Hesaplı,</div>
            </div>
            <span className={classes.BannerTitleRegular}>Herkes Kazançlı!</span>
          </div>
          <p className={classes.BannerText}>
            Yeni nesil online yemek siparişi uygulaması <b>Gofody</b>,
            hayatınızı kolaylaştıracak seçeneklerle bi’ tık uzağınızda. Binlerce
            restoran ve binlerce yemek çeşidi ile her zevke ve bütçeye uygun
            seçenekler uygulamada sizi bekliyor. Üstelik diğer online yemek
            siparişi uygulamalarından da çok farklı! <br /> <br />
            Gofody, online sipariş sisteminin yanı sıra, Gel Al ve Gel Otur
            sistemleriyle de kullanıcılara farklı bir deneyim yaşatıyor.
          </p>
          <div className={classes.DownloadIconsArea}>
            <a
              href="https://apps.apple.com/app/id1546612606"
              className={classes.DownloadIcons}
            >
              <div>
                <img src={appstore} height="48px"></img>
              </div>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.gofody.food.android"
              className={classes.DownloadIcons}
            >
              <div>
                <img src={playstore} height="48px"></img>
              </div>
            </a>
          </div>
          <Link href={"/restaurant"}>
            <div
              className={classes.BannerButtonWrapper}
              onClick={() => history.push("/restaurant")}
            >
              <img src={AddIcon} className={classes.AddIcon} />
              <span className={classes.ButtonText}>Restoran Başvurusu</span>
            </div>
          </Link>
        </div>
        <div className={classes.ImageWrapper}>
          <img className={classes.BannerImage} src={BannerImage}></img>
        </div>
      </div>
      <div className="narea">
        <ul className="circles">
          <li>
            <img src={A1} style={{ opacity: 0.3 }} />
          </li>
          <li>
            <img src={A2} style={{ opacity: 0.3 }} />
          </li>
          <li>
            <img src={A3} style={{ opacity: 0.3 }} />
          </li>
          <li>
            <img src={A4} style={{ opacity: 0.3 }} />
          </li>
          <li>
            <img src={A5} style={{ opacity: 0.3 }} />
          </li>
          <li>
            <img src={A6} style={{ opacity: 0.3 }} />
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Banner;
