import * as Yup from "yup";

const ValidationSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  surname: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email").required("Required"),
  subject: Yup.string().required("Required"),
  phoneNumber: Yup.string().required("Required"),
  message: Yup.string().required("Required"),
});

export default ValidationSchema;
