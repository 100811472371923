import React from "react";
import classes from "./input.module.scss";

const Input = ({
  placeholder,
  onChange,
  value,
  style,
  color,
  info,
  type,
  error,
}) => {
  return (
    <>
      <div style={style} className={classes.InputContainer}>
        <input
          style={{
            backgroundColor:
              value.length && type === 1 ? "#f5f5f5" : color && color,
            border: error && "2px solid #f57355",
          }}
          onChange={onChange}
          className={classes.Input}
          placeholder={placeholder}
        />
        {info && <span className={classes.textInfo}>{info}</span>}
        {value.length > 0 && (
          <span className={classes.Label}>{placeholder}</span>
        )}
        {value.length === 0 && (
          <span
            style={{ color: error && "#f57355" }}
            className={classes.Required}
          >
            *zorunlu
          </span>
        )}
      </div>
    </>
  );
};

export default Input;
