const paymentTypeConverter = (payment_type) => {
  if (payment_type == "onlinecard") return "Online Ödeme";
  if (payment_type == "cash") return "Nakit";
  if (payment_type == "card") return "Kapıda Kartla Ödeme";
  if (payment_type == "creditcard") return "Online Ödeme";
  if (payment_type == "multinet") return "Multinet";
  if (payment_type == "ticket") return "Ticket";
  if (payment_type == "sodexo") return "Sodexo";
  if (payment_type == "paye") return "Paye";
  if (payment_type == "setcard") return "Setcard";
  if (payment_type == "metropol") return "Metropol";
  if (payment_type == "yemekmatik") return "Yemekmatik";
  if (payment_type == "sodexoMobile") return "Sodexo Mobil";
  return payment_type;
};

export default paymentTypeConverter;
