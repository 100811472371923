import React from "react";
import classes from "./select.module.scss";
import Select from "react-select";

const SelectComponent = ({
  placeholder,
  color,
  marginLeft,
  borderRadius,
  options,
  value,
  type,
  onChange,
  tabSelectedValue,
  error,
}) => {
  const customStyles = {
    control: () => ({
      display: "flex",
      backgroundColor:
        value.length && type === 1 ? "#f5f5f5" : color ? color : "#f5f5f5",
      width: "100%",
      borderRadius: borderRadius ? borderRadius : "10px",
      height: "54px",
      border: error && "2px solid #f57355",
    }),
    indicatorContainer: () => ({
      width: "90px",
      backgroundColor: "#000000 !important",
    }),
    indicatorSeparator: () => ({
      backgroundColor: "#f5f5f5",
    }),
    menu: () => ({
      backgroundColor: "#cccccc",
      borderRadius: "10px",
      position: "absolute",
      zIndex: "10",
      width: "100%",
    }),
    container: () => ({
      backgroundColor: "#cccccc",
      borderRadius: borderRadius ? borderRadius : "10px",

      marginLeft: marginLeft ? marginLeft : "",
    }),
    singleValue: () => ({
      color: "#000000",
      fontFamily: "Roboto",
      fontSize: "16px",
      paddingLeft: "16px",
      paddingTop: "20px",
    }),
    placeholder: () => ({
      color: "#7e7e7e",
      fontFamily: "Roboto",
      fontSize: "18px",
      paddingLeft: "16px",
    }),
    option: (provided, state) => ({
      paddingTop: "10px",
      paddingLeft: "24px",
      width: "100%",
      fontSize: "16px",
      color: "#000000",
      fontFamily: "Roboto",
    }),
  };

  return (
    <>
      <div className={classes.InputContainer}>
        <Select
          onChange={onChange}
          tabSelectsValue={tabSelectedValue}
          value={value}
          placeholder={placeholder}
          styles={customStyles}
          options={options}
        />
        {value.length > 0 && (
          <span className={classes.Label}>{placeholder}</span>
        )}
        {value.length === 0 && (
          <span
            style={{ color: error && "#f57355" }}
            className={classes.Required}
          >
            *zorunlu
          </span>
        )}
      </div>
    </>
  );
};

export default SelectComponent;
