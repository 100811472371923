import React from "react";
import classes from "./visual.module.scss";
import { Link } from "react-router-dom";
const Logo = process.env.PUBLIC_URL + "/images/reserved-dining-table.svg";

const Visuals = ({ id }) => {
  return (
    <>
      <div id={id} className={classes.Visual}>
        <div className={classes.VisualContainer}>
          <div className={classes.VisualContent}>
            <h3 className={classes.VisualTitle}>
              <span className={classes.Yellow}>
                Restoran
                <br /> Sahibi misiniz?{" "}
              </span>
            </h3>
            <p className={classes.VisualText}>
              Yeni nesil yemek kültürüne adapte olmuş ve vizyon sahibi bir
              restoran iseniz, Gofody ailesine hemen katılın. Gofody restoran iş
              ortaklarından biri olun, işinizi daha az maliyetle büyütün,
              müşteri memnuniyetinizi ve bilinirliğinizi artırın.
            </p>
            <Link to="/restaurant">
              <div className={classes.VisualButton}>
                <span className={classes.VisualButtonText}>Hemen Başvur</span>
              </div>
            </Link>
          </div>
          <div>
            <img src={Logo} className={classes.VisualImage} />
          </div>
        </div>
      </div>
    </>
  );
};

export default Visuals;
