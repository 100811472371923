import React from "react";

import Card from "@mui/material/Card";

export default function Default({ children, ...props }) {
  return <Card {...props}>{children}</Card>;
}

// Card.propTypes = {
//   className: PropTypes.string,
//   plain: PropTypes.bool,
//   profile: PropTypes.bool,
//   blog: PropTypes.bool,
//   raised: PropTypes.bool,
//   background: PropTypes.bool,
//   pricing: PropTypes.bool,
//   testimonial: PropTypes.bool,
//   color: PropTypes.oneOf([
//     "primary",
//     "info",
//     "success",
//     "warning",
//     "danger",
//     "rose",
//   ]),
//   product: PropTypes.bool,
//   chart: PropTypes.bool,
//   login: PropTypes.bool,
//   children: PropTypes.node,
// };
