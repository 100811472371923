import {useEffect} from "react";
import {
    useHistory
  } from "react-router-dom";


/**
 * Determine the mobile operating system.
 * This function returns one of 'iOS', 'Android', 'Windows Phone', or 'unknown'.
 *
 * @returns {String}
 */
 function getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
  
        // Windows Phone must come first because its UA also contains "Android"
      if (/windows phone/i.test(userAgent)) {
          return "Windows Phone";
      }
  
      if (/android/i.test(userAgent)) {
          return "Android";
      }
  
      // iOS detection from: http://stackoverflow.com/a/9039885/177710
      if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          return "iOS";
      }
  
      return "unknown";
  }

export default function LandingPage() {
    const history = useHistory();
    useEffect(() => {
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            // Redirect to mobile
            const platform = getMobileOperatingSystem();
                //Detect if android
                if(platform == "iOS"){
                    window.location.href = "https://apps.apple.com/app/id1546612606";
                }else if(platform == "Android"){
                    window.location.href = "https://play.google.com/store/apps/details?id=com.gofody.food.android" ;
                }else{
                    history.push("/");
                }
                //Detect if ios
        }else{
            // Redirect to home
            history.push("/");
        }
    }, [])

    return (
        <></>
    )
}