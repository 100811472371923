import React, { useEffect } from "react";
import classes from "./kvkk.module.scss";

const Kvkk = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div
      style={{
        width: "80%",
        margin: "100px 10%",
        fontFamily: "Roboto",
        fontSize: 16,
        lineHeight: 1.3,
      }}
    >
      <h1>VERİ AKTARIM İLİŞKİSİNE DAİR PROTOKOL</h1>
      <ol>
        <li className={classes.item}>
          <b> Giriş</b>
        </li>
        <br /> İşbu Veri Aktarım Protokol’ü (“Protokol”), BURHANİYE MAH.
        VASİFBEY SK. GÜNDOĞDU AVUKATLIK BLOK NO: 2 İÇ KAPI NO: 1
        ÜSKÜDAR/İSTANBUL adresinde bulunan{" "}
        <b>GOFODY YAZILIM TEKNOLOJİLERİ ANONİM ŞİRKETİ</b> (Bundan böyle
        “GOFODY” diye anılacaktır) ile [...] adresinde mukim [...] (“Şirket”)
        arasında [...] konusunda alınan hizmete ilişkin olarak __/__/2021
        tarihinde imza altına alınmıştır. <br />
        <br />
        <b>GOFODY</b> ve <b>Şirket</b> ayrı ayrı <b>“Taraf”</b>, birlikte ise{" "}
        <b>“Taraflar”</b> olarak anılacaktır.
        <br />
        <br />
        <li className={classes.item}>
          <b> Konu</b>
        </li>
        <br />
        <b>GOFODY</b> ve <b>Şirket</b> arasındaki işbu Protokol uyarınca
        işlenen/işlenecek kişisel verilere yönelik olarak Taraflar’ın hak ve
        yükümlülüklerin düzenlenmesinden ibarettir.
        <br />
        <br />
        <br />
        <li className={classes.item}>
          <b> Tanımlar</b>
        </li>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>İlgili Kişi:</p>
          <p className={classes.descStyle}>
            Kişisel verisi işlenen gerçek kişiyi,
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>Kanun veya KVKK: </p>
          <p className={classes.descStyle}>
            {" "}
            6698 sayılı Kişisel Verilerin Korunması Kanunu’nu,{" "}
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>Kişisel Veri: </p>
          <p className={classes.descStyle}>
            Kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü
            bilgiyi,
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}> Veri işleme:</p>
          <p className={classes.descStyle}>
            Kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi
            bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan
            yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza
            edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması,
            aktarılması, devralınması, elde edilebilir hale getirilmesi,
            sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler
            üzerinde gerçekleştirilen her türlü işlemi,
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>Veri Aktaran: </p>
          <p className={classes.descStyle}>
            {" "}
            Tarafların aralarındaki hizmet ilişkisine uygun olarak kişisel
            verileri aktaran Taraf’ı,{" "}
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>Veri Aktarılan: </p>
          <p className={classes.descStyle}>
            Veri Aktaran tarafından kendisine kişisel veriler aktarılan Taraf’ı,{" "}
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>Veri Sorumlusu: </p>
          <p className={classes.descStyle}>
            Kişisel verilerin işleme amaçlarını ve vasıtalarını belirleyen, veri
            kayıt sisteminin kurulmasından ve yönetilmesinden sorumlu olan
            gerçek veya tüzel kişiyi,{" "}
          </p>
        </div>
        <div className={classes.rowStyle}>
          <p className={classes.titleStyle}>Veri İşleyen: </p>
          <p className={classes.descStyle}>
            {" "}
            Veri Sorumlusu’nun verdiği yetkiye dayanarak onun adına kişisel
            verileri işleyen gerçek veya tüzel kişiyi{" "}
          </p>
        </div>
        <br /> ifade etmektedir.
        <br />
        <br />
        <li className={classes.item}>
          <b>Tarafların Protokol Kapsamındaki Yükümlülükleri </b>
        </li>
        <br />
        <ol className={classes.foo}>
          <li>
            <b>4.1.</b> Veri Aktaran,
          </li>
          <ol>
            <li>
              <b>4.1.1. </b>
              Hizmet kapsamındaki Kişisel Verileri, Kanun’da sayılan hukuka
              uygunluk nedenlerine uygun şekilde elde ettiğini, ve diğer Taraf’a
              aktarmaya yetkili olduğunu,
            </li>
            <li>
              <b>4.1.2.</b> İlgili Kişiler’e yönelik verinin işlenmesi ve
              aktarılmasına ilişkin aydınlatmayı "Aydınlatma Yükümlülüğünün
              Yerine Getirilmesinde Uyulacak Usul ve Esaslar Hakkında Tebliğ”e
              ve ilgili mevzuata uygun şekilde yapmış olduğunu, kabul, beyan ve
              taahhüt eder.
            </li>
          </ol>
          <br />
          <li>
            <b>4.2.</b> Veri Aktarılan,
          </li>
          <ol>
            <li>
              <b>4.2.1.</b> Veri Aktaran tarafından kendisine aktarılan Kişisel
              Verileri, Kanun başta olmak üzere, ilgili mevzuata ve Kişisel
              Verileri Koruma Kurulu kararlarına uygun şekilde işleyeceğini,
            </li>
            <li>
              <b>4.2.2.</b> Kendisine aktarılan veya erişimine açılan Kişisel
              Verileri Hizmet kapsamındaki aktarım amacı dışında
              kullanmayacağını,
            </li>
            <li>
              <b>4.2.3.</b> İşleme amacının değişmesi durumunda; bu işleme
              amacının KVKK ve ilgili mevzuata uygunluğunun kontrol edilmesi
              yükümlülüğünün kendisinde olduğunu,
            </li>
            <li>
              <b>4.2.4.</b> Veri işleme amacının değişmesi durumunu Veri
              Aktaran’a derhal bildireceğini,
            </li>
            <li>
              <b>4.2.5.</b> Veri Aktaran’ın böyle bir veri işlemeye karşı
              gerekli önlemleri alma hak ve yetkisine sahip olduğunu, bu nedenle
              doğacak tüm masraflardan kendisinin sorumlu olacağını,
            </li>
            <li>
              <b>4.2.6.</b> Hizmet ilişkisinde kararlaştırılan Veri İşleme amacı
              dışındaki veri işleme faaliyeti nedeniyle doğacak tüm sorumluluğun
              kendisine ait olduğunu,
            </li>
            <li>
              <b>4.2.7.</b> Bu ikincil işleme faaliyeti nedeniyle ilgili
              kişileri "Aydınlatma Yükümlülüğünün Yerine Getirilmesinde Uyulacak
              Usul ve Esaslar Hakkında Tebliğ”e uygun şekilde aydınlatacağını
              kabul, beyan ve taahhüt eder.
            </li>
          </ol>
          <li>
            <br />
            <b>4.3.</b>
            <b style={{ color: "red" }}>
              [Veri Aktarılan’ın bulut hizmeti kullanması, bakım-destek hizmeti
              alması vb. durumlarda alt veri işleyen kullandığı göz önüne
              alınmalıdır.]
            </b>
            Alt Veri İşleyen Kullanımı
          </li>
          <ol>
            <li>
              <b>4.3.1.</b> Veri Aktarılan, Hizmet İlişkisinin amacı
              doğrultusunda ve Veri Aktaran’ın yazılı iznini almak kaydıyla,
              herhangi bir alt yükleniciyi veri işleme faaliyetleri için yazılı
              bir sözleşme vasıtasıyla yetkilendirebilir.
            </li>
            <li>
              <b>4.3.2.</b> Veri Aktarılan yetkilendirilen alt yüklenicinin
              işleme faaliyetlerinin Kanun’a ve ilgili mevzuata uygun olmasını
              sağlamakla ve bu hususta ilgili denetimleri gerçekleştirmekle
              yükümlüdür.
            </li>
            <li>
              <b>4.3.3.</b> Yetkilendirilen alt yükleniciler Veri Aktaran’a
              karşı Kanun ve işbu Protokol’den doğan yükümlülükler kapsamında
              Veri Aktarılan gibi sorumlu olacaklardır.
            </li>
          </ol>
        </ol>
        <br />
        <li className={classes.item}>
          <b> Verilerin Aktarılmasına Dair Yükümlülükler </b>
        </li>
        <ol className={classes.foo}>
          <li>
            <b>5.1.</b> Veri Aktarılan, Hizmet kapsamındaki kişisel verileri,
            hukuki yükümlülükleri saklı kalmak kaydıyla, Veri Aktaran’ın yazılı
            izni olmaksızın Hizmet İlişkisinde belirlenen edimlerinin ifası
            amacı dışında yurt içinde veya yurt dışında üçüncü kişi ya da
            kurumlara aktarmayacaktır.
          </li>
          <li>
            <b>5.2.</b> Veri Aktarılan, kişisel verilerin yurt içinde veya yurt
            dışında üçüncü kişi ya da kurumlara aktarımı ile ilgili gerekli
            politika ve prosedürleri yerine getirmekle sorumludur.
          </li>
          <li>
            <b>5.3.</b> Veri Aktarılan’ın, kişisel verileri ilgili mevzuat veya
            hizmet ilişkisi hükümleri uyarınca üçüncü kişilere aktarmasının
            gerekli olduğu hallerde, Veri Aktarılan hangi verinin aktarıldığını,
            verinin aktarma amacının ne olduğunu, verinin aktarılması sonucunda
            ilgili kişilerin haklarının korunması için ne gibi önlemler
            alındığını ve ilgili diğer hususları en geç 24 saat içinde Veri
            Aktaran’a bildirmekle yükümlüdür.
          </li>
        </ol>
        <br />
        <li className={classes.item}>
          <b> Verilerin Saklanmasına ve Yok Edilmesine Dair Yükümlülükler </b>
        </li>
        <ol className={classes.foo}>
          <li>
            <b>6.1.</b> Veri Aktarılan ve varsa yetkilendirdiği alt yüklenici,
            Veri Aktaran’ın aktardığı kişisel verileri, Hizmet ilişkisi
            kapsamındaki edimlerinin ifası için gerekli olan sürenin veya ifanın
            niteliği gereği yasal bir saklama süresi öngörülmüşse belirlenen
            sürenin sonuna kadar saklamayı ve bu sürenin sona ermesi halinde
            aktarılan kişisel verilere ilişkin kayıtları ve bu kişisel verilerin
            kopyalarını yok etmeyi, silmeyi veya anonim hale getirmeyi kabul,
            beyan ve taahhüt eder.
          </li>
        </ol>
        <li className={classes.item}>
          <b>
            İlgili Kişilerin Kanun Kapsamındaki Başvurularına Dair Yükümlülükler
          </b>
        </li>
        <ol className={classes.foo}>
          <li>
            <b>7.1</b> Taraflar, İlgili Kişiler tarafından kişisel verileri ile
            ilgili olarak kendilerine yapılacak olan ve hizmet ilişkisi
            kapsamındaki işleme süreçlerini etkileyen her türlü başvuruyu en
            kısa sürede ve yazılı olarak diğer Taraf’a iletecektir.
          </li>
          <li>
            <b>7.2</b>
            Veri Aktarılan, İlgili Kişiler’in talepleri ile ilgili olarak Veri
            Aktaran’ın talimatları doğrultusunda hareket edecektir. Veri
            Aktarılan, herhangi bir nedenle yerine getirilemeyen talimatlarla
            alakalı olarak, Veri Aktaran’ı en kısa sürede yazılı olarak
            bilgilendirmekle yükümlüdür.
          </li>
        </ol>
        <li className={classes.item}>
          <b> Veri Güvenliğine Dair Yükümlülükler</b>
        </li>
        <ol className={classes.foo}>
          <li>
            <b>8.1.</b> Taraflar, kişisel verilerin güvenliğine ilişkin olarak
            gerekli ve güncel teknoloji ile uyumluluk arz eden her türlü idari
            ve teknik tedbiri almakla ve bu bağlamda Veri Aktaran tarafından
            kendisine verilecek tüm talimatlara uymakla yükümlüdür.
          </li>
          <li>
            <b>8.2.</b> Veri Aktarılan, oluşabilecek güvenlik açığı, siber
            saldırı ve veri sızıntısı hallerinde, söz konusu açık, saldırı veya
            sızıntıdan haberdar olduğu tarihten itibaren en geç 24 saat
            içerisinde Veri Aktaran’a bildirimde bulunmakla yükümlüdür. Buna ek
            olarak, Veri Aktarılan kişisel verilerin kazara kaybolması,
            bozulması, tahrif edilmesi ya da yok olması hallerinde ilgili duruma
            ilişkin detayları içeren olay raporunu en kısa sürede ve yazılı
            olarak Veri Aktaran’a iletecektir.{" "}
          </li>
        </ol>
        <li className={classes.item}>
          <b> Denetime Dair Yükümlülükler</b>
        </li>
        <ol className={classes.foo}>
          <li>
            <b>9.1.</b> Veri Aktarılan, işlemekte olduğu kişisel verilerin
            paylaşılmasına ilişkin olarak, kanunen yetkili kılınmış merciler
            tarafından yapılan hukuka uygun her türlü talebi Veri Aktaran’a
            bildirmekle yükümlüdür.
          </li>
          <li>
            <b>9.2.</b> Veri Aktarılan, Veri Aktaran tarafından kişisel
            verilerin aktarımına yönelik olarak yapılacak her türlü denetime
            ilişkin desteği tam olarak ve gecikmeksizin sağlayacaktır.
          </li>
          <li>
            <b>9.3.</b> Veri Aktarılan, Veri Aktaran’ın talebi üzerine, Hizmet
            kapsamındaki Veri işleme faaliyetlerini, Veri Aktaran’a ya da Veri
            Aktaran tarafından seçilen bağımsız üyelerden oluşan denetim
            kurulunun denetimine sunacaktır.{" "}
          </li>
          <li>
            <b>9.4.</b> Veri Aktarılan, Veri işleme faaliyetlerine ilişkin
            olarak Kişisel Verileri Koruma Kurumu tarafından yapılacak
            denetimlere yönelik her türlü idari ve teknik desteği sağlamakla
            yükümlüdür.
          </li>
        </ol>
        <li className={classes.item}>
          <b> Sorumluluk ve Tazminat</b>
        </li>
        <ol className={classes.foo}>
          <li>
            <b>10.1</b> Veri Aktarılan, işbu Protokol’den kaynaklanan
            yükümlülüklerini gerektiği gibi ifa edememesi, yükümlülüklerini
            yerine getirirken gecikme olması veya Hizmet kapsamında
            yetkilendirdiği alt yüklenicilerin teknik ve idari tedbirleri
            almaması nedeniyle ortaya çıkacak doğrudan ve dolaylı her türlü
            zarardan sorumlu olacaktır.
          </li>
          <li>
            {" "}
            <b>10.2</b> Taraflar’dan herhangi birinin, işbu Protokol
            hükümlerinin ihlali de dahil olmak üzere, karşı Taraf’tan
            kaynaklanan nedenlerle zarara uğraması, yasal bir yaptırıma tabi
            tutulması ya da herhangi bir zararı tazminle yükümlü tutulması
            halinde, söz konusu tutarlara ilişkin olarak karşı Taraf’a rücu
            hakkı saklıdır.
          </li>
        </ol>
      </ol>
      <br />
      <br />
    </div>
  );
};

export default Kvkk;
