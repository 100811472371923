import React, { useEffect } from "react";
import Header from "../../containers/header";
import classes from "./kvkk.module.scss";
const Kvkk2 = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <Header type="kvkk" />
      <div style={{ width: "90%", margin: "150px auto", maxWidth: "1000px" }}>
        <p>
          <h1>
            KİŞİSEL VERİLERİN İŞLENMESİNE İLİŞKİN TEDARİKÇİ ÇALIŞANI AYDINLATMA
            METNİ
          </h1>
          <br />
          <b>Veri Sorumlusu : </b> Veri Sorumlusu: GOFODY YAZILIM TEKNOLOJİLERİ
          ANONİM ŞİRKETİ Burhaniye Mah. Vasifbey Sk. No: 2 İç Kapı No: 1
          Üsküdar/İstanbul
          <br />
          GOFODY Yazılım Teknolojileri A.Ş (bundan sonra “<b>GOFODY</b>” olarak
          anılacaktır.) olarak; kişisel verilerinizi 6698 sayılı Kişisel
          Verilerin Korunması Kanunu (“<b>KVKK</b>”) ve ilgili mevzuat ve yasal
          düzenlemelerden kaynaklanan faaliyetleri çerçevesinde kişisel
          verilerinizin işlenmesi, saklanması ve aktarılması ile ilgili siz alt
          yüklenici çalışanlarını bilgilendirmek amacıyla işbu aydınlatma
          metnini hazırladık
          <br />
          <br />
          <b>
            Hangi Kişisel Verileriniz İşlenmekte ve Kişisel Verilerinizin
            İşlenme Amaçları Nelerdir?
          </b>
          <br />
          <br />
          Faaliyetlerimiz ve istihdam amaçlarımız kapsamında siz tedarikçi
          çalışanlarımızın aşağıda yer alan verilerini toplamaktayız:
          <br />
          <br />
          {/* <b style={{ textAlign: "center", margin: "0 auto" }}>
            İşlenen Kişisel Verileriniz
          </b> */}
          <br />
          <br />
          {/** Kimlik bilgileri Bloğu Başlangıç */}
          <div className={classes.RowColored}>
            <div>Kimlik Bilgileri</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Ad</li>
                <li>Soyad</li>
                <li>İmza</li>
                <li>Araç Plaka/Konum Bilgisi</li>
                <li>Fotoğraf</li>
                <li>Doğum Tarihi</li>
                <li>Cinsiyet</li>
                <li>Medeni Durum</li>
                <li>Personel Sicil Numarası</li>
                <li>T.C. Kimlik Numarası</li>
                <li>Doğum Yeri</li>
                <li>Anne-Baba Adı</li>
                <li>Uyruk</li>
                <li>Kimlik Fotokopisi</li>
                <li>Mesleki Kimlik Fotokopisi</li>
                <li>Yabancı Kimlik Numarası</li>
                <li>Pasaport Numarası</li>
                <li>Ehliyet Numarası</li>
                <li>Ruhsat Numarası</li>
              </ul>
            </div>

            <div>
              Mal/Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi, İş
              Faaliyetlerinin Yürütülmesi/Denetimi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Mal/Hizmet Satış Süreçlerinin Yürütülmesi, Müşteri
              Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Ürün/Hizmetlerin
              Pazarlama Süreçlerinin Yürütülmesi, Pazarlama Analiz
              Çalışmalarının Yürütülmesi, Çalışanlar İçin İş Akdi Ve Mevzuattan
              Kaynaklı Yükümlülüklerin Yerine Getirilmesi, Eğitim
              Faaliyetlerinin Yürütülmesi, Yetkili Kişi, Kurum Ve Kuruluşlara
              Bilgi Verilmesi, Çalışan Memnuniyeti Ve Bağlılığı Süreçlerinin
              Yürütülmesi, Organizasyon Ve Etkinlik Yönetimi,
              Reklam/Kampanya/Promosyon Süreçlerinin Yürütülmesi, Çalışanlar
              İçin Yan Haklar Ve Menfaatleri Süreçlerinin Yürütülmesi, Sözleşme
              Süreçlerinin Yürütülmesi, İş Sağlığı/Güvenliği Faaliyetlerinin
              Yürütülmesi, Denetim/Etik Faaliyetlerinin Yürütülmesi, Fiziksel
              Mekan Güvenliğinin Temini, Bilgi Güvenliği Süreçlerinin
              Yürütülmesi, Taşınır Mal Ve Kaynakların Güvenliğinin Temini, Veri
              Sorumlusu Operasyonlarının Güvenliğinin Temini, Ziyaretçi
              Kayıtlarının Oluşturulması Ve Takibi, Finans Ve Muhasebe İşlerinin
              Yürütülmesi, İş Sürekliliğinin Sağlanması Faaliyetlerinin
              Yürütülmesi, İç Denetim/Soruşturma/İstihbarat Faaliyetlerinin
              Yürütülmesi, Hukuk İşlerinin Takibi Ve Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                  ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                  verilerin işlenmesinin gerekli olması,
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması,
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz basılı ve elektronik
              formlar, vekaletnameler, sözleşmeler, elektronik takip sistemleri,
              CCTV kayıtları ile toplanmaktadır.
            </div>
          </div>
          {/** End of first Block */}
          {/* İletişim Bilgilerinin İşlenmesi */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>İletişim Bilgileri</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>E-Posta</li>
                <li>Cep Telefonu Numarası </li>
                <li>İş Telefonu Numarası </li>
                <li>İş Yeri İsmi/Unvanı</li>
                <li>İş Yeri Adresi</li>
                <li>Sosyal Medya Hesap İsimleri</li>
                <li>İkametgah Adresi</li>
                <li>Ev Telefonu Numarası</li>
              </ul>
            </div>

            <div>
              Mal/Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi, İş
              Faaliyetlerinin Yürütülmesi/Denetimi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Mal/Hizmet Satış Süreçlerinin Yürütülmesi, Müşteri
              Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Çalışanlar İçin
              İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin Yerine Getirilmesi,
              Eğitim Faaliyetlerinin Yürütülmesi, Yetkili Kişi, Kurum Ve
              Kuruluşlara Bilgi Verilmesi, Çalışan Memnuniyeti Ve Bağlılığı
              Süreçlerinin Yürütülmesi, Organizasyon Ve Etkinlik Yönetimi,
              Reklam/Kampanya/Promosyon Süreçlerinin Yürütülmesi, Pazarlama
              Analiz Çalışmalarının Yürütülmesi, Çalışanlar İçin Yan Haklar Ve
              Menfaatleri Süreçlerinin Yürütülmesi, Sözleşme Süreçlerinin
              Yürütülmesi, İş Sağlığı/Güvenliği Faaliyetlerinin Yürütülmesi,
              Denetim/Etik Faaliyetlerinin Yürütülmesi, Fiziksel Mekan
              Güvenliğinin Temini, Bilgi Güvenliği Süreçlerinin Yürütülmesi,
              Taşınır Mal Ve Kaynakların Güvenliğinin Temini, Veri Sorumlusu
              Operasyonlarının Güvenliğinin Temini, Ziyaretçi Kayıtlarının
              Oluşturulması Ve Takibi, Finans Ve Muhasebe İşlerinin Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’inci maddesinde
              belirtilen;
              <ul>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruyailgili
                  olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
                  işlenmesinin gerekli olması,
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar
                  vermemekkaydıyla, veri sorumlusunun meşru menfaatleri için
                  veri işlenmesinin zorunlu olması,
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz basılı ve elektronik
              formlar vasıtasıyla toplanmaktadır.
            </div>
          </div>
          {/* SON İletişim Bilgilerinin İşlenmesi */}
          <br />
          <br />
          {/* Özlük Bilgilerinin İşlenmesi */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>Özlük Bilgileri</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Pozisyon/Unvan Bilgisi</li>
                <li>Disiplin Soruşturması</li>
                <li>Özgeçmiş Bilgileri</li>
                <li>SGK İşe Giriş Bildirgesi </li>
                <li>ISG Raporları ve Belgeleri</li>
                <li>Vardiya Bilgileri</li>
                <li>Kişisel Koruyucu Malzeme/Zimmet Bilgileri</li>
                <li>Puantaj Bilgileri</li>
                <li>Çalışmaya Uygunluk Raporu</li>
                <li>Bordro Bilgileri</li>
                <li>Zimmet Belgesi</li>
              </ul>
            </div>

            <div>
              Mal/Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi, İş
              Faaliyetlerinin Yürütülmesi/Denetimi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Mal/Hizmet Satış Süreçlerinin Yürütülmesi, Müşteri
              Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Ürün/Hizmetlerin
              Pazarlama Süreçlerinin Yürütülmesi, Pazarlama Analiz
              Çalışmalarının Yürütülmesi, Çalışanlar İçin İş Akdi Ve Mevzuattan
              Kaynaklı Yükümlülüklerin Yerine Getirilmesi, Eğitim
              Faaliyetlerinin Yürütülmesi, Yetkili Kişi, Kurum Ve Kuruluşlara
              Bilgi Verilmesi, Çalışan Memnuniyeti Ve Bağlılığı Süreçlerinin
              Yürütülmesi, Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi,
              Organizasyon Ve Etkinlik Yönetimi, Reklam/Kampanya/Promosyon
              Süreçlerinin Yürütülmesi, Çalışanlar İçin Yan Haklar Ve
              Menfaatleri Süreçlerinin Yürütülmesi, Sözleşme Süreçlerinin
              Yürütülmesi, İş Sağlığı/Güvenliği Faaliyetlerinin Yürütülmesi,
              Denetim/Etik Faaliyetlerinin Yürütülmesi, Bilgi Güvenliği
              Süreçlerinin Yürütülmesi, Fiziksel Mekan Güvenliğinin Temini,
              Taşınır Mal Ve Kaynakların Güvenliğinin Temini, Veri Sorumlusu
              Operasyonlarının Güvenliğinin Temini, Ziyaretçi Kayıtlarının
              Oluşturulması Ve Takibi, Finans Ve Muhasebe İşlerinin Yürütülmesi,
              İş Sürekliliğinin Sağlanması Faaliyetlerinin Yürütülmesi, İç
              Denetim/Soruşturma/İstihbarat Faaliyetlerinin Yürütülmesi, Hukuk
              İşlerinin Takibi Ve Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruyailgili
                  olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
                  işlenmesinin gerekli olması,
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar
                  vermemekkaydıyla, veri sorumlusunun meşru menfaatleri için
                  veri işlenmesinin zorunlu olması,
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz vekaletnameler,
              sözleşmeler, basılı ve elektronik formlar ile elektronik takip
              sistemleri vasıtasıyla toplanmaktadır.
            </div>
          </div>
          {/* SON Özlük Bilgilerinin İşlenmesi */}
          <br></br>
          <br></br>
          {/* Mesleki Deneyim İşlenmesi */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>Mesleki Deneyim Bilgileri</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>İSG Eğitim Belgesi</li>
                <li>Meslek İçi Eğitim Bilgileri</li>
                <li>Sertifika Bilgisi</li>
                <li>Gidilen Kurs Bilgileri</li>
              </ul>
            </div>

            <div>
              Çalışanlar İçin İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin
              Yerine Getirilmesi, Çalışanlar İçin Yan Haklar Ve Menfaatleri
              Süreçlerinin Yürütülmesi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Hukuk İşlerinin Takibi Ve Yürütülmesi, Sözleşme
              Süreçlerinin Yürütülmesi, İş Sağlığı/Güvenliği Faaliyetlerinin
              Yürütülmesi, Denetim/Etik Faaliyetlerinin Yürütülmesi, Yetkili
              Kişi, Kurum ve Kuruluşlara Bilgi Verilmesi, İş Faaliyetlerinin
              Yürütülmesi/Denetimi, Bilgi Güvenliği Süreçlerinin Yürütülmesi,
              Fiziksel Mekan Güvenliğinin Temini, Taşınır Mal Ve Kaynakların
              Güvenliğinin Temini, Veri Sorumlusu Operasyonlarının Güvenliğinin
              Temini, Ziyaretçi Kayıtlarının Oluşturulması Ve Takibi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruyailgili
                  olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
                  işlenmesinin gerekli olması,
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar
                  vermemekkaydıyla, veri sorumlusunun meşru menfaatleri için
                  veri işlenmesinin zorunlu olması,
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz basılı ve elektronik
              formlar vasıtasıyla toplanmaktadır.
            </div>
          </div>
          {/* SON Mesleki Deneyim Bilgilerinin İşlenmesi */}
          {/* Görsel ve İşitsel Kayıtlar */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>Görsel ve İşitsel Kayıtlar</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Görsel Kayıtlar</li>
                <li>İşitsel Kayıtlar</li>
                <li>CCTV (Görüntü Fotoğrafları)</li>
              </ul>
            </div>

            <div>
              Mal/Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi, İş
              Faaliyetlerinin Yürütülmesi/Denetimi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Mal/Hizmet Satış Süreçlerinin Yürütülmesi, Müşteri
              Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Çalışan
              Memnuniyeti Ve Bağlılığı Süreçlerinin Yürütülmesi, Müşteri
              İlişkileri Yönetimi Süreçlerinin Yürütülmesi, Organizasyon Ve
              Etkinlik Yönetimi, Reklam/Kampanya/Promosyon Süreçlerinin
              Yürütülmesi, Pazarlama Analiz Çalışmalarının Yürütülmesi, İş
              Sağlığı/Güvenliği Faaliyetlerinin Yürütülmesi, Denetim/Etik
              Faaliyetlerinin Yürütülmesi, Yetkili Kişi, Kurum ve Kuruluşlara
              Bilgi Verilmesi, Bilgi Güvenliği Süreçlerinin Yürütülmesi,
              Fiziksel Mekan Güvenliğinin Temini, Taşınır Mal Ve Kaynakların
              Güvenliğinin Temini, Veri Sorumlusu Operasyonlarının Güvenliğinin
              Temini, Ziyaretçi Kayıtlarının Oluşturulması Ve Takibi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>Kişinin açık rızası,</li>

                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruyailgili
                  olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
                  işlenmesinin gerekli olması,
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar
                  vermemekkaydıyla, veri sorumlusunun meşru menfaatleri için
                  veri işlenmesinin zorunlu olması,
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz basılı ve elektronik
              formlar, elektronik takip sistemleri, CCTV kayıtları vasıtasıyla
              toplanmaktadır
            </div>
          </div>
          {/* SON Görsel ve İşitsel Kayıtlar */}
          <br></br>
          <br></br>
          {/* Hukuki İşlem Bilgisi */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>Hukuki İşlem Bilgisi</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Adli Makamlarla Yazışmalardaki Bilgiler</li>
                <li>İdari Makamlarla Yazışmalardaki Bilgiler</li>
                <li>Dava Dosyasındaki Bilgiler</li>
                <li>Maaş Haczi vb.İlgili kayıtlar</li>
                <li>İhtarname/İhbarname Kapsamındaki Bilgiler</li>
                <li>Sözleşme Bilgisi</li>
              </ul>
            </div>

            <div>
              Çalışanlar İçin Yan Haklar Ve Menfaatleri Süreçlerinin
              Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Hukuk
              İşlerinin Takibi Ve Yürütülmesi, Sözleşme Süreçlerinin
              Yürütülmesi, İş Sağlığı/Güvenliği Faaliyetlerinin Yürütülmesi,
              Denetim/Etik Faaliyetlerinin Yürütülmesi, Fiziksel Mekan
              Güvenliğinin Temini, Yetkili Kişi, Kurum ve Kuruluşlara Bilgi
              Verilmesi, Çalışanlar İçin İş Akdi Ve Mevzuattan Kaynaklı
              Yükümlülüklerin Yerine Getirilmesi, Hukuk İşlerinin Takibi Ve
              Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>Kanunlarda açıkça öngörülmesi,</li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz vekaletnameler,
              sözleşmeler, basılı ve elektronik formlar vasıtasıyla
              toplanmaktadır.
            </div>
          </div>
          {/* SON Hukuki İşlem Bilgisi */}
          <br></br>
          <br></br>
          {/* Fiziksel Mekan Güvenliği Bilgisi */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>Fiziksel Mekan Güvenliği Bilgisi</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>CCTV Kayıt Bilgileri</li>
              </ul>
            </div>

            <div>
              Bilgi Güvenliği Süreçlerinin Yürütülmesi, Fiziksel Mekan
              Güvenliğinin Temini, Taşınır Mal Ve Kaynakların Güvenliğinin
              Temini, Veri Sorumlusu Operasyonlarının Güvenliğinin Temini,
              Ziyaretçi Kayıtlarının Oluşturulması Ve Takibi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                  ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                  verilerin işlenmesinin gerekli olması,
                </li>
                <li>
                  Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi
                  için zorunlu olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz basılı ve elektronik
              formlar, elektronik takip sistemleri, CCTV kayıtları vasıtasıyla
              toplanmaktadır.
            </div>
          </div>
          {/* SON Fiziksel Mekan Güvenliği Bilgisi */}
          <br></br>
          <br></br>
          {/* Lokasyon Bilgisi */}
          <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>Lokasyon Bilgisi</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Araç Takip Sistemi Verileri</li>
              </ul>
            </div>

            <div>
              Mal/Hizmet Üretim Ve Operasyon Süreçlerinin Yürütülmesi, İş
              Faaliyetlerinin Yürütülmesi/Denetimi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Mal/Hizmet Satış Süreçlerinin Yürütülmesi, Müşteri
              Memnuniyetine Yönelik Aktivitelerin Yürütülmesi, Ürün/Hizmetlerin
              Pazarlama Süreçlerinin Yürütülmesi, Pazarlama Analiz
              Çalışmalarının Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                  ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                  verilerin işlenmesinin gerekli olması,
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz elektronik takip
              sistemleri vasıtasıyla toplanmaktadır. Uygulama içinde karşınıza
              çıkan izin ekranına izin verdikten sonra Uygulama üzerinden bir
              aksiyon alarak hizmet verdiğiniz süre boyunca ve bu iki husus
              gerçekleştiğinde uygulama açıkken veya uygulama arka plandayken
              veya uygulama tamamen kapalıyken erişilmektedir.  
              Bu uygulama sipariş takibi özelliğini etkinleştirmek için konum verilerini toplar, uygulama kapalıyken veya kullanımda değilken bile
            </div>
          </div>
          {/* SON Lokasyon Bilgisi */}
          <br></br>
          <br></br>
          {/* İşlem Güvenliği Bilgisi */}
          {/* <br></br>
          <br></br>
          <div className={classes.RowColored}>
            <div>İşlem Güvenliği Bilgisi</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>IP Bilgisi</li>
                <li>Browser Bilgisi</li>
              </ul>
            </div>

            <div>
              Firma/Ürün/Hizmetlere Bağlılık Süreçlerinin Yürütülmesi, İletişim
              Faaliyetlerinin Yürütülmesi, Müşteri İlişkileri Yönetimi
              Süreçlerinin Yürütülmesi, Müşteri Memnuniyetine Yönelik
              Aktivitelerin Yürütülmesi, Talep/Şikayetlerin Takibi, Bilgi
              Güvenliği Süreçlerinin Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              Kişisel verileriniz, GOFODY tarafından yukarıda sayılan amaçların
              gerçekleştirilmesi doğrultusunda, başta KVKK’nın 5’incimaddesinde
              belirtilen;
              <ul>
                <li>
                  Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya
                  ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel
                  verilerin işlenmesinin gerekli olması,
                </li>
                <li>
                  İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                  kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                  işlenmesinin zorunlu olması
                </li>
              </ul>
              hukuki sebeplerine dayanılarak işlenmektedir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel verileriniz elektronik formlar
              vasıtasıyla toplanmaktadır.
            </div>
          </div> */}
          {/* SON İşlem Güvenliği Bilgisi */}
          <br></br>
          <b>
            Özel Nitelikli Kişisel Verilerinizin Toplanmasının Hukuki Sebebi
            Nedir?
          </b>
          <br />
          Özel nitelikli kişisel veriler; kişinin ırkı, etnik kökeni, siyasi
          düşüncesi, felsefi inancı, dini, mezhebi veya diğer inançları, kılık
          ve kıyafeti, dernek, vakıf ya da sendika üyeliği, sağlığı, cinsel
          hayatı, ceza mahkûmiyeti ve güvenlik tedbirleriyle ilgili verileri ile
          biyometrik ve genetik verileridir.
          <br />
          Özel nitelikli kişisel veriler, diğer veri kategorilerine oranla daha
          yüksek koruma gerektirirler. Bu tür verileri işlemek için ayrıca
          bilgilendirme yapılması gerektiği KVKK uyarınca düzenlenmiştir.
          <br />
          <br />
          {/* Sağlık Bilgileri  */}
          <div className={classes.RowColored}>
            <div>Özel Nitelikli Kişisel Veriler</div>
          </div>
          <div className={classes.RowColored}>
            <div>Sağlık Bilgileri</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Periyodik Muayene Raporu</li>
                <li>
                  İş Kazası Raporu(tedavi bilgileri,yaralanma yapısı,sigara
                  kullanımı, alkolkullanımı, kronikhastalık bilgisi,kullandığı
                  ilaç bilgisi,anamnez bilgileri, fizikimuayene ve bulgulara
                  dair bilgiler)
                </li>
                <li>
                  Sağlık Raporu(Kan grubu bilgisi, kronik hastalık bilgisi, aşı
                  bilgisi, tetanoz/hepatit hastalıkları/diğer bağışıklık
                  hastalık bilgisi, tıbbi anamnez bilgileri, tıbbi şikayet
                  bilgisi, hastalık bilgisi, ameliyat bilgisi, iş kazası
                  bilgisi, meslek hastalığı bilgisi, tetkik/muayene bilgisi,
                  maluliyet bilgisi, tedavi bilgisi, sigara ve/veya alkol
                  kullanımı bilgisi, fiziki muayene bilgisi, laboratuvar analiz
                  bilgileri, çalışmaya elverişlilik bilgisi, çalışmaya uygunluk
                  bilgisi, yüksekte çalışmaya uygunluk bilgisi vb.)
                </li>
              </ul>
            </div>

            <div>
              Çalışanlar İçin Yan Haklar Ve Menfaatleri Süreçlerinin
              Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Hukuk
              İşlerinin Takibi Ve Yürütülmesi, Sözleşme Süreçlerinin
              Yürütülmesi, İş Sağlığı/Güvenliği Faaliyetlerinin Yürütülmesi,
              Denetim/Etik Faaliyetlerinin Yürütülmesi, Fiziksel Mekan
              Güvenliğinin Temini, Yetkili Kişi, Kurum ve Kuruluşlara Bilgi
              Verilmesi, İş Faaliyetlerinin Yürütülmesi/Denetimi, Çalışanlar
              İçin İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin Yerine
              Getirilmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              GOFODY olarak sağlık verilerinizi, yukarıda yer alan amaçlar
              doğrultusunda, KVKK’nın 6. maddesinde belirtilen “Sağlık ve cinsel
              hayata ilişkin kişisel verilerin ancak kamu sağlığının korunması,
              koruyucu hekimlik, tıbbî teşhis, tedavi ve bakım hizmetlerinin
              yürütülmesi, sağlık hizmetleri ile finansmanının planlanması ve
              yönetimi amacıyla, sır saklama yükümlülüğü altında bulunan kişiler
              veya yetkili kurum ve kuruluşlar tarafından ilgilinin açık rızası
              aranmaksızın işlenebileceği” hukuki sebebine dayanarak
              işlemekteyiz. Sağlık verilerinizin KVKK’nın 6. maddesinde
              belirlenen sınırlamalar haricinde işlenmesi ancak açık rızanıza
              bağlı olarak gerçekleşecektir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel veriniz basılı ve elektronik formlar
              vasıtasıyla toplanmaktadır.
            </div>
          </div>
          {/* SON Sağlık Bilgileri */}
          <br />
          <br />
          {/* Sağlık Bilgileri  */}
          <div className={classes.RowColored}>
            <div>Özel Nitelikli Kişisel Veriler</div>
          </div>
          <div className={classes.RowColored}>
            <div>Ceza Mahkumiyeti ve Güvenlik Tedbirleri</div>
          </div>
          <div className={classes.RowColored}>
            <div>İşlenen Kişisel Veriler</div>
            <div>Kişisel Veri İşleme Amaçları</div>
          </div>
          <div className={classes.Row}>
            <div>
              <ul>
                <li>Adli Sicil Kaydı</li>
              </ul>
            </div>

            <div>
              Çalışanlar İçin İş Akdi Ve Mevzuattan Kaynaklı Yükümlülüklerin
              Yerine Getirilmesi, Çalışanlar İçin Yan Haklar Ve Menfaatleri
              Süreçlerinin Yürütülmesi, Faaliyetlerin Mevzuata Uygun
              Yürütülmesi, Hukuk İşlerinin Takibi Ve Yürütülmesi, Sözleşme
              Süreçlerinin Yürütülmesi
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verilerinizin İşlenmesindeki Hukuki Sebepler</div>
            <div>
              GOFODY olarak Ceza Mahkumiyeti ve Güvenlik Tedbirleri
              verilerinizi, yukarıda yer alan amaçlar doğrultusunda, KVKK’nın 6.
              maddesinde belirtilen “Birinci fıkrada sayılan sağlık ve cinsel
              hayat dışındaki kişisel veriler, kanunlarda öngörülen hâllerde
              ilgili kişinin açık rızası aranmaksızın işlenebileceği” hukuki
              sebebine dayanarak işlemekteyiz. Ceza Mahkumiyeti ve Güvenlik
              Tedbirleri verilerinizin KVKK’nın 6. maddesinde belirlenen
              sınırlamalar haricinde işlenmesi ancak açık rızanıza bağlı olarak
              gerçekleşecektir.
            </div>
          </div>
          <div className={classes.RowFirst}>
            <div>Kişisel Verileri Toplama Yöntemleri</div>
            <div>
              Yukarıda belirtilen kişisel veriniz basılı ve elektronik formlar
              vasıtasıyla toplanmaktadır.
            </div>
          </div>
          {/* SON Sağlık Bilgileri */}
          <b>
            Kişisel Verileriniz Kimlere ve Hangi Amaçlarla Aktarılabilecektir?
          </b>
          <div>
            Kişisel verileriniz,
            <div>
              <ul>
                <li>
                  Sözleşme süreçlerinin yürütülmesi,bilgi güvenliği süreçlerinin
                  yürütülmesi,organizasyon ve etkinlik yönetimi, iş
                  faaliyetlerinin yürütülmesi, iş
                  sağlığı/güvenliğifaaliyetlerinin yürütülmesi, finans ve
                  muhasebe işlerinin yürütülmesi amaçlarıyla varsa{" "}
                  <b>İştirakler ve Bağlı Ortaklıklar</b>’a
                </li>
                <li>
                  Yasal düzenlemenin öngördüğü kapsamda, faaliyetlerin mevzuata
                  uygunyürütülmesi, yetkili kişi, kurum ve kuruluşlara bilgi
                  verilmesi, iş sağlığı/güvenliğifaaliyetlerinin yürütülmesi
                  amaçlarıyla <b>Yetkili Kamu Kurum ve Kuruluşları</b>’na
                </li>
                <li>
                  Mal/Hizmet üretim ve operasyon süreçlerinin yürütülmesi, veri
                  sorumlusuoperasyonlarının güvenliğinin temini amaçlarıyla
                  <b>Tedarikçiler</b>’e,
                </li>
                <li>
                  Mal/hizmet üretim ve operasyon süreçlerinin yürütülmesi,
                  mal/hizmet satışsüreçlerinin yürütülmesi, müşteri
                  memnuniyetine yönelik aktivitelerin yürütülmesiamaçlarıyla{" "}
                  <b>Gerçek Kişiler veya Özel Hukuk Tüzel Kişileri</b>’ne
                </li>
              </ul>
            </div>
            aktarılabilecektir.
          </div>
          <div>
            GOFODY A.Ş. olarak, faaliyetimizin kapsaması halinde verinizin
            işlendiği süreç yurt dışıbağlantılı ise iş faaliyetlerinin
            yürütülmesi amacı ile gerekli teknik ve idari tedbirleri alarak,
            bukişilerle ve süreçlerle sınırlı veriler Kanun’daki kurallara uygun
            olarak yurt dışındaki iştirakler,bağlı ortaklıklar, tedarikçiler ile
            ilgili ülkedeki mevzuat zorunlulukları durumunda yabancıkamu kurum
            ve kuruluşları ile paylaşılabilecektir.
          </div>
          <br></br>
          <b>Kişisel Verileriniz Ne Kadar Süre ile Saklanır?</b>
          <div>
            Kişisel verileriniz, kanunda veya ilgili mevzuatta verinin
            saklanması için bir sürebelirlenmişse, söz konusu veri en az bu süre
            kadar saklanmak zorundadır. Kanunda veyailgili mevzuatta bir süre
            öngörülmemişse; işlenme amaçlarıyla bağlantılı, sınırlı ve ölçülü
            olmak kaydıyla belirlenen makul sürelerce saklanmaktadır.
          </div>
          <br></br>
          <b>İlgili Kişi Olarak Haklarınız Nelerdir?</b>
          <div>
            KVKK ve ilgili mevzuat kapsamında kişisel verilerinize ilişkin
            olarak;
            <ul>
              <li>Kişisel verilerinizin işlenip işlenmediğini öğrenme,</li>
              <li>
                Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
              </li>
              <li>
                Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </li>
              <li>
                Yurt içinde veya yurt dışında kişisel verilerinizin aktarıldığı
                üçüncü kişileri bilme,
              </li>
              <li>
                Kişisel verilerinizin eksik veya yanlış işlenmiş olması halinde
                bunların düzeltilmesini isteme,
              </li>
              <li>
                KVKK mevzuatında öngörülen şartlar çerçevesinde kişisel
                verilerinizin silinmesini veya yok edilmesini isteme,
              </li>
              <li>
                Eksik veya yanlış verilerin düzeltilmesi ile kişisel
                verilerinizin silinmesi veya yok edilmesini talep ettiğinizde,
                bu durumun kişisel verilerinizi aktardığımız üçüncü kişilere
                bildirilmesini isteme,
              </li>
              <li>
                Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğramanız halinde bu zararın giderilmesini talep etme
              </li>
            </ul>
            haklarına sahipsiniz.
          </div>
          <b>Haklarınızı Nasıl Kullanabilirsiniz?</b>
          <div>
            Kişisel verileriniz ile ilgili başvuru ve taleplerinizi dilerseniz;
            <ul>
              <li>
                Islak imzalı ve kimlik fotokopisi ile Burhaniye Mah. Vasifbey
                Sk. No: 2 İç Kapı No: 1 Üsküdar/İstanbul adresine göndererek,
              </li>
              <li>
                Geçerli bir kimlik belgesi ile birlikte GOFODY YAZILIM
                TEKNOLOJİLERİ A.Ş’ye bizzat başvurarak,{" "}
              </li>
              <li>
                Mobil imza veya güvenli elektronik imza ile imzalayıp
                <a href="mailto:kvk@gofody.com">kvk@gofody.com</a> adresine
                e-posta gönderilerek,
              </li>
            </ul>
            GOFODY Yazılım Teknolojileri A.Ş’ye iletebilirsiniz.
          </div>
          <br></br>
          <div>
            Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ uyarınca,
            İlgili Kişi’nin, başvurusunda isim, soyisim, başvuru yazılı ise
            imza, T.C. kimlik numarası (başvuruda bulunan kişinin yabancı olması
            halinde uyruğu, pasaport numarası veya varsa kimlik numarası),
            tebligata esas yerleşim yeri veya iş yeri adresi, varsa bildirime
            esas elektronik posta adresi, telefon numarası ve faks numarası ile
            talep konusuna dair bilgilerin bulunması zorunludur.
          </div>
          <br></br>
          <div>
            İlgili Kişi, yukarıda belirtilen hakları kullanmak için yapacağı ve
            kullanmayı talep ettiği hakka ilişkin açıklamaları içeren başvuruda
            talep edilen hususu açık ve anlaşılır şekilde belirtmelidir.
            Başvuruya ilişkin bilgi ve belgelerin başvuruya eklenmesi
            gerekmektedir.
          </div>
          <br></br>
          <div>
            Talep konusunun başvuranın şahsı ile ilgili olması gerekmekle
            birlikte, başkası adına hareket ediliyor ise başvuruyu yapanın bu
            konuda özel olarak yetkili olması ve bu yetkinin belgelendirilmesi
            (vekâletname) gerekmektedir. Ayrıca başvurunun kimlik ve adres
            bilgilerini içermesi ve başvuruya kimliği doğrulayıcı belgelerin
            eklenmesi gerekmektedir.
          </div>
          <br></br>
          <div>
            Yetkisiz üçüncü kişilerin başkası adına yaptığı talepler
            değerlendirmeye alınmayacaktır.
          </div>
          <br></br>
          <b>
            Kişisel Verilerinizin İşlenmesine İlişkin Talepleriniz Ne Kadar
            Sürede Cevaplanır?
          </b>
          <div>
            Kişisel verilerinize ilişkin hak talepleriniz değerlendirilerek,
            bize ulaştığı tarihten itibaren en geç 30 gün içerisinde cevaplanır.
            Başvurunuzun olumsuz değerlendirilmesi halinde gerekçeli ret
            sebepleri başvuruda belirttiğiniz adrese elektronik posta veya posta
            yolu başta olmak üzere İlgili Kişi Başvuru Formu’nda seçilen
            usullerinden biri ile gönderilir.
          </div>
        </p>
      </div>
    </>
  );
};

export default Kvkk2;
